import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { instance } from '../../../utils/ApiRequest';
import OrderShop from './OrderShop';
import OrderPrint from './OrderPrint';
import Pagination from './Pagination';

function Orders() {
  const [shopOrders, setShopOrders] = useState([]);
  const [printOrders, setPrintOrders] = useState([]);
  const [shopCurrentPage, setShopCurrentPage] = useState(1); // Current page
  const [shopTotalPages, setShopTotalPages] = useState(0); // Total number of pages
  const [printCurrentPage, setPrintCurrentPage] = useState(1);
  const [printTotalPages, setPrintTotalPages] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  const FetchShopOrders = useCallback(() => {
    instance.get(`/account/orders/shop/list?page=${shopCurrentPage}&limit=10`)
    .then(res => {
      if(res.data){
        setShopOrders(res.data.Data.Rows);
        setShopTotalPages(res.data.Data.Meta.TotalPages)
        // setShopCurrentPage(res.data.Data.Meta.CurrentPage)
      }
    })
    .catch(err => {
      
    })
  },[shopCurrentPage])

  const handleShopPageChange = (pageNumber) => {
    setShopCurrentPage(pageNumber);
  };
  
  const handlePrintPageChange = (pageNumber) => {
    setPrintCurrentPage(pageNumber);
  }

  const FetchPrintOrders = useCallback(async() => {
    instance.get('/account/orders/print/list')
    .then(res => {
      if(res.data){
        setPrintOrders(res.data.Data.Rows);
        setPrintTotalPages(res.data.Data.Meta.TotalPages)
      }
    })
    .catch(err => {
      
    })
  },[])

  useEffect(() => {
    if(location.pathname === "/orders/shop"){
      FetchShopOrders();
    }else{
      FetchPrintOrders();
    }
  }, [location, FetchPrintOrders, FetchShopOrders])

  const tabChange = (path) => {
    navigate(path);
}
  return (
    <div className='orders_list tab-pane'>
        <Tabs selectedTabClassName="show" defaultIndex={location.pathname === "/orders/shop" ? 0 : 1 }>
          <TabList className="nav nav-pills nav-fill">
            <Tab className="nav-item" onClick={() => tabChange('/orders/shop')}>
                <span className="nav-link">Shop</span>
            </Tab>
            <Tab className="nav-item" onClick={() => tabChange('/orders/print')}>
                <span className="nav-link">Print</span>
            </Tab>
          </TabList>
          <div className="tab-content">
            <TabPanel style={ { paddingTop: "2rem" } }>
              <OrderShop shopOrders = {shopOrders}/>
              {shopTotalPages > 1 && <Pagination totalPages={shopTotalPages} currentPage={shopCurrentPage} onPageChange={handleShopPageChange} />}
            </TabPanel>
            <TabPanel style={ { paddingTop: "2rem" } }>
              <OrderPrint printOrders={printOrders} />
              {printTotalPages > 1 && <Pagination totalPages={printTotalPages} currentPage={printCurrentPage} onPageChange={handlePrintPageChange} />}
            </TabPanel>
          </div>
        </Tabs>
        
    </div>
  )
}

export default Orders