import React from 'react';
import './order.css';
import RasorPay from '../Payment/RasorPay';
import { useLocation } from 'react-router-dom';

function OrderSuccss({OrderDetail}) {
    const location = useLocation();
  return (
    <div className='order-received'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='message-box'>
                        <div className='success-container'>
                            <br />
                            <img src="https://scontent-lcy1-1.xx.fbcdn.net/v/t1.6435-9/31301640_2114242505489348_3921532491046846464_n.png?_nc_cat=104&ccb=1-3&_nc_sid=973b4a&_nc_ohc=pfOalMq8BzUAX-k-rhY&_nc_ht=scontent-lcy1-1.xx&oh=3af014dd12fa6e3d1816a3425a80e516&oe=609BE04A" alt="" />
                            <br />
                            <h1 className="monserrat-font" style={{color:"Grey"}}>Thank you for your order</h1>
                            <br />
                            <div className="confirm-green-box">
                                <br />
                                <h5>ORDER CONFIRMATION</h5>
                                <p>Your order {OrderDetail && "#"+OrderDetail.OrderID} has been sucessful!</p>
                                <p>Thank you for choosing Ocean 3D. You will shortly receive a confirmation email.</p>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center mt-5">
                {
                    location.pathname.includes('/print') ? 
                    <a href={process.env.REACT_APP_PRINT_URL} className="btn btn-outline-primary margin-left-5px">Continue customizing</a>
                    : 
                    <a href={process.env.REACT_APP_SHOP_URL} className="btn btn-outline-primary margin-left-5px">Continue shiopping</a>
                }
            </div>
        </div>
        <RasorPay OrderDetail={OrderDetail && OrderDetail}/>
    </div>
  )
}

export default OrderSuccss;