import { configureStore } from "@reduxjs/toolkit";
import Address from "./Address";
import UserData from "./UserData";

const store = configureStore({
    reducer:{
        UserData: UserData,
        AddressList: Address
    }
})

export default store;