import React, { useState } from 'react';
import useSize from '../../Size/useSize';
import RasorPay from '../Payment/RasorPay';
import { instance } from '../../../utils/ApiRequest';
import { getState } from '../../../utils';

function OrderDetail({OrderData}) {
    const [retryPayment, setRetryPayment] = useState(false);
    const [retryPaymentID, setRetryPaymentID] = useState("");

    const width = useSize();

    const user = localStorage.getItem('user_logged_in');

    const order_active = {
        "Pending": 0,
        "Confirmed": 1,
        "Printing": 2,
        "Processed": 3,
        "Packed": 4,
        "Shipped": 5,
        "OutForDelivery": 6,
        "Delivered": 7,
        "Returned" : 8,
        "Cancelled" : 9
    }

    const handleButtonClick = () => {
        if(OrderData.ShippingDetail.TrackingNumber !== null){
            window.open(`https://ocean3d.shiprocket.co/tracking/${OrderData.ShippingDetail.TrackingNumber}`, '_blank');
        }
    };
    
    const handleRetryPayment = async(orderId) => {
        await instance.post('/account/orders/shop/retry-razorpay-payment',{
            "OrderID": orderId
        })
        .then(res => {
            if(res.data.Data.RazorpayOrderId){
                setRetryPayment(true);
                setRetryPaymentID(res.data.Data.RazorpayOrderId);
            }else{
                window.location.reload();
            }
        })
        .catch(err => {
        })
    }

    function getReadableDate(date) {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        // Get the day suffix (st, nd, rd, th)
        let daySuffix;
        if (day % 10 === 1 && day !== 11) {
            daySuffix = 'st';
        } else if (day % 10 === 2 && day !== 12) {
            daySuffix = 'nd';
        } else if (day % 10 === 3 && day !== 13) {
            daySuffix = 'rd';
        } else {
            daySuffix = 'th';
        }
        
        return `${day}${daySuffix} ${month}, ${year}`;
    }
    const date = new Date(OrderData.ShippingDetail.ETD);

    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);

    const shippingState = getState(OrderData.ShippingAddress.State);
    const billingState = getState(OrderData.BillingAddress.State);

    // Format the date as "Aug 05, 2024"
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    const formattedNextDay = nextDay.toLocaleDateString('en-US', options);

    const status_num = order_active.hasOwnProperty(OrderData.OrderStatus) ? order_active[OrderData.OrderStatus] : 0 ;
    // const status_num = 6 ;
    const placed = status_num >= 1 ? "Confirmed" : "Placed";
    const Processed = (status_num > 3 || status_num >= 2) ? status_num > 2 ? "Processed" : "Processing" : "Yet to Processing";
    const shipment = (status_num > 4 || status_num > 3) ? status_num > 4 ? "Shipped" : "Packed" : "Yet to shipping";
    const delivered = (status_num > 6 || status_num > 5) ? status_num > 6 ? status_num > 7 ? status_num > 8 ? "Cancelled" : "Returned" : "Delivered" : "Out for delivery" : "Yet to delivery";
  return (
    <div className='card'>
        <div className='row'>
            <div className='col-md-6 tracking_wrap'>
                    {
                    status_num > 8 ? 
                    <div className='cancelled'>
                        <h3>Order Cancelled</h3>
                    </div>
                        :
                    <div className="tracking-wrapper">
                        <div className="tracking">
                            <div id="progress" className={`progress-${status_num >= 7 ? 7 : status_num}`}>
                            <div className="empty-bar"></div>
                            <div className="color-bar"></div>
                            <ul>
                                <li className="bullet-1">
                                    <div className="el"><i className='checkmark'></i></div>
                                    <div className="txt">{placed}</div>
                                </li>
                                <li className="bullet-2">
                                    <div className="el"><i className='checkmark'></i></div>
                                    <div className="txt">{Processed}</div>
                                </li>
                                <li className="bullet-3">
                                    <div className="el"><i className='checkmark'></i></div>
                                    <div className="txt">{shipment}</div>
                                </li>
                                <li className="bullet-4">
                                    <div className="el"><i className='checkmark'></i></div>
                                    <div className="txt">{delivered}</div>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>

                    }
                {
                    status_num > 8 &&
                    <div className='payment_retry_payment'>
                        <span>{OrderData.CancelData.Reason}</span>
                    </div>
                }
                {
                    status_num < 7 &&
                    <div className='payment_retry_payment'>
                        {
                        (OrderData.PaymentDetail.PaymentMethod !== "Cash On Delivery" && OrderData.PaymentDetail.PaymentStatus !== "Paid") ?
                        <div className='payment_retry'>
                            <span className='danger'>Pending Payment</span>
                            <span className='retry' onClick={() => handleRetryPayment(OrderData.OrderID)}>Retry / check</span>
                        </div>
                        :
                        <div className='payment_retry'>
                            <span className='danger'></span>
                            <span className='retry'></span>
                        </div>
                        }
                        {
                            OrderData.ShippingDetail.TrackingNumber == null ? 
                            <div className='tooltipText'>
                                <span className={`btn btn-primary`} title="Tracker available once order shipped!">Track order</span>
                                <p className='tooltip_text'>Tracker available once order shipped!</p>
                            </div>
                            :
                            <span className={`btn btn-primary`} onClick={handleButtonClick}>Track order</span>
                        }
                    </div>
                }
            </div>
            <div className='col-md-6 order_detail'>
                <h4>Order Summary</h4>
                <div className='order-data'>
                    <span>Order #<strong>{OrderData.OrderID}</strong></span>
                    <span>Order placed on<strong> { getReadableDate(new Date(OrderData.OrderDate))}</strong></span>
                    {status_num < 7 && <span>Estimated Delivery by <strong>{formattedNextDay}</strong></span>}
                    <span>Total <strong>₹{OrderData.PricingDetail.TotalPrice}</strong></span>
                </div>
            </div>
        </div>
        <div className='row mt-2'>
            <h4>Order Items</h4>
            <div className='col-md-7 order_table'>
                {
                 ( width > 1025 && !user) ?
                    <table className="table table-cart table-mobile">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Sze</th>
                                <th>Material</th>
                                <th>Color</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { OrderData.CheckoutItems.length > 0 ?
                                OrderData.CheckoutItems.map( ( item, index ) => {
                                    let size = item.Size === "SM" ? "Small" : item.Size === "MD" ? "Medium" : item.Size === "RG" ? "Regular" : "";
                                    return (
                                        <tr key={ index }>
                                            <td className="product-col">
                                                <div className="product">
                                                    <figure className="product-media">
                                                    <a className="product-image" href="/product/65e1ddd44a7cf7915c5e95af/RGGDGR10001">
                                                        <img src={item.ImageURL} alt={ item.ProductName } />
                                                    </a>
                                                    </figure>
                                                    <div className='product-disc'>
                                                        <h4 className="product-title">{ item.ProductName }</h4>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="price-col">
                                                {size}
                                            </td>
                                            <td className="price-col">
                                                {item.MaterialName}
                                            </td><td className="price-col">
                                                {item.ColorName}
                                            </td>
                                            <td className="price-col">
                                                <del>₹{item.Price}</del>{" "}₹{item.SalePrice}
                                            </td>

                                            <td className="quantity-col">
                                            {item.Quantity}
                                            </td>

                                            <td className="total-col">
                                            <del>₹{item.Price}</del>{" "}₹{item.SalePrice}
                                            </td>
                                        </tr>
                                    )
                                }
                                ) :
                                <tr>
                                    <td>
                                        <p className="pl-2 pt-1 pb-1"> No Products in Cart </p>
                                    </td>
                                </tr>
                            }

                        </tbody>
                    </table>
                 :
                    <div className='order_table_customized'>
                        { OrderData.CheckoutItems.length > 0 &&
                            OrderData.CheckoutItems.map( ( product, index ) => {
                            let size = product.Size === "SM" ? "Small" : product.Size === "MD" ? "Medium" : product.Size === "RG" ? "Regular" : "";
                            return (
                                <div className='item' key={index}>
                                    <figure className="product-media-loaded">
                                        <span className="product-image">
                                            <img src={product.ImageURL } alt = { product.ProductName } />
                                        </span>
                                    </figure>
                                    <div className='product-details'>
                                        <h4 className="product-title">
                                            <span>{ product.ProductName }</span>
                                        </h4>
                                        <span className='mat-col'>
                                            {product.MaterialName} - {product.ColorName} 
                                        </span>
                                        <span className='addional'>
                                            <span className='mat-col'><span className='strong'>Size:</span> {size}</span>
                                            <span className="out-price">₹<del>{ product.Price }</del>{" "}{product.SalePrice}</span>
                                        </span>
                                        <div className='actions'>
                                            <div className="dropdown">
                                            <span> <span className='strong'>Quantity:</span> { product.Quantity }</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                            })
                        }
                    </div>

                }
            </div>
            <div className='col-md-5 order_payment'>
                <div className='shipping_payment'>
                    <div className="price-details price-details-head">
                        <p>Shipping</p>
                        <p>Shipping Charges</p>
                        <p>Payment method:</p>
                        <p>Subtotal:</p>
                        <p>IGST:</p>
                        <p>Total:</p>
                    </div>
                    <div className="price-details">
                        <p>{OrderData.ShippingDetail.CourierName}</p>
                        <p>₹{OrderData.PricingDetail.ShippingCharges}</p>
                        <p>{OrderData.PaymentDetail.PaymentMethod}</p>
                        <p>₹{OrderData.PricingDetail.CartTotalExclusiveTax}</p>
                        <p>₹{OrderData.PricingDetail.GST}</p>
                        <p>₹{OrderData.PricingDetail.TotalPrice || 0}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='row mt-2'>
            <div className='col-md-6 shipping_info'>
                <h3 className="card-title">Shipping Address</h3>
                <address>
                    {OrderData.ShippingAddress.FullName},<br />
                    {OrderData.ShippingAddress.Email},<br />
                    {OrderData.ShippingAddress.Mobile},<br />
                    {OrderData.ShippingAddress.Address1},<br />
                    {OrderData.ShippingAddress.Address2},<br />
                    {OrderData.ShippingAddress.City}<br />
                    {shippingState.label},{" "}
                    {OrderData.ShippingAddress.Pincode}<br />
                </address>
            </div>
            <div className='col-md-6 billing_info'>
                <h3 className="card-title">Billing Address</h3>
                <address>
                    {OrderData.BillingAddress.FullName},<br />
                    {OrderData.BillingAddress.Email},<br />
                    {OrderData.BillingAddress.Mobile},<br />
                    {OrderData.BillingAddress.Address1},<br />
                    {OrderData.BillingAddress.Address2},<br />
                    {OrderData.BillingAddress.City},<br />
                    {billingState.label},{" "}
                    {OrderData.BillingAddress.Pincode}
                </address>
            </div>
        </div>
        {retryPayment && <RasorPay OrderDetail={OrderData && OrderData} RazorpayOrderId={retryPaymentID}/>}
    </div>
  )
}

export default OrderDetail