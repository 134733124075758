import React from 'react';
import { Link } from 'react-router-dom';
import {ReactComponent as OfferIcon} from './offers.svg';
import TopHeader from './UserTopHeader';
import StickyHeader from '../StickyHeader';
import MainMenu from './main-menu';

function Header () {
    const headerHight = document.getElementsByClassName("header-top");

    function openMobileMenu () {
        document.querySelector( 'body' ).classList.add( 'mmenu-active' );
    }

    function OnClickOffers(){
        window.open(`${process.env.REACT_APP_ACCOUNT_URL}coupons`, '_self');
    }


    return (
        <header className="header header-14">
            <div className="header-top bg-body-tertiary">
                <TopHeader />    
            </div>
            <StickyHeader headerHight={headerHight}>
                <div className="header-bottom sticky-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-auto col-lg-3 col-xl-3 col-xxl-2 header-left">
                                <button className="mobile-menu-toggler" onClick={ openMobileMenu }>
                                    <span className="sr-only">Toggle mobile menu</span>
                                    <i className="icon-bars"></i>
                                </button>
                                <Link to={process.env.REACT_APP_HOME_URL} className="logo">
                                    <img src={"https://cdn.ocean3d.in/global/logo-white.png"} alt="ocean-logo"/>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                                </Link>
                            </div>
                            <div className="col col-lg-6 col-xl-6 col-xxl-8 header-center">
                                <MainMenu />
                            </div>
                            <div className="col col-lg-3 col-xl-3 col-xxl-2 header-right" onClick={OnClickOffers}>
                                <OfferIcon /><p>Offers</p>
                            </div>
                        </div>

                    </div>
                </div>
            </StickyHeader>
        </header>

    )
}

export default Header;