import React, { useEffect, useState } from "react";
import "./summary.css";
import { instance } from "../../../utils/ApiRequest";
import { Link, useNavigate } from "react-router-dom";
import CustomSelect from "./CustomShipping";
import useSize from "../../Size/useSize";
import CouponsList from "./CouponsList";
import Loader from "../../Loader";
import { getState } from "../../../utils";

function SummaryDetails({setOrderDetail}) {
  // const isLoggedIn = localStorage.getItem('user_logged_in');
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [SummaryData, setSummaryData] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const [isError, setIsError] = useState(false);
  const [err_msg, set_err_msg] = useState('');
  const [validOTP, setValidOTP] = useState('');
  const [coupon_err, set_coupon_err] = useState('');
  const [coupon, setCoupon] = useState('');
  const [showCoupons, setShowCoupons] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [time, setTime] = useState(60); // Timer starts at 60 seconds
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [code, setCode] = useState(['', '', '', '']);

  const navigate = useNavigate();
  const size = useSize();
  async function FetchSummary() {
    await instance
      .get("/account/checkout/shop/summary")
      .then((res) => {
        if (res.data.Status === "Success") {
          setSummaryData(res.data.Data);
        }
      })
      .catch((err) => {
        
        setIsError(true);
        set_err_msg(err.response.data.Message);
      });
  }


  const toggleList = () => {
    setShowCoupons(false);
    document.querySelector( 'body' ).classList.remove( 'overflow' );
  };
  
  async function ApplyCoupon(coupon){
    const data = SummaryData.PricingDetail.PromoCode ? {} : {
      "CouponCode": coupon
    };
    if(SummaryData.PricingDetail.PromoCode){
      await instance.put('/account/checkout/shop/remove-coupon')
      .then(res => {
        if(res.data.Status === "Success"){
          FetchSummary();
        }
      }).catch(err => {
        
        set_coupon_err(err.response.data.Message);
      })
    }else{
      if(!coupon){
        set_coupon_err("coupon shouldn't be empty!");
      }else{
        await instance.put('/account/checkout/shop/apply-coupon',data)
        .then(res => {
          if(res.data.Status === "Success"){
            FetchSummary();
          }
        }).catch(err => {
          
          set_coupon_err(err.response.data.Message);
        })
      }
    }

    setTimeout(() => {
      set_coupon_err('')
    }, 3000);
  }

  async function ShippingChangeOptions(value) {
    if(value){
      await instance.put('/account/checkout/shop/update-shipping-method',{
        "CourierId": value
      })
      .then(res => {
        if(res.data.Status === "Success"){
          FetchSummary();
        }
        
      }).catch(err => {
        
      })
  }
  }
  const FetchPaymentOptions = async () => {
    await instance
      .get("/account/checkout/shop/payment-methods")
      .then((res) => {
        if (res.data.Status === "Success") {
          setPaymentOptions(res.data.Data);
        }
      })
      .catch((err) => {
        
      });
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (value.length <= 1 && /^[0-9]*$/.test(value)) {
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);
        setValidOTP('');
        // Move focus to the next input field if a digit is entered
        if (value && index < code.length - 1) {
            document.getElementById(`input-${index + 1}`).focus();
        }
    }
};

const validateUser = () => {
  const UserId = SummaryData && SummaryData.UserId;
  if(UserId !== null){
      PlaceOrder();
  }else{
      setShowPopup(true);
      // setTime(60);
      // setIsTimerActive(true);
      requestOTP(SummaryData && SummaryData.ShippingAddress.Mobile);
  }
}
const mobChange = () => {
  navigate('/checkout/shop/shipping', { state: {mobile_change: "true"} })
}

useEffect(() => {
    let timer = null;
    if (isTimerActive && time > 0) {
        timer = setInterval(() => {
            setTime(prevTime => prevTime - 1);
        }, 1000); // Decrease time every second
    } else if (time === 0) {
        setIsTimerActive(false); // Stop the timer when time is up
    }
    return () => clearInterval(timer); 
}, [isTimerActive, time])

  async function PlaceOrder(){
    await instance.post('/account/orders/shop/place')
    .then(res => {
      if(res.data.Status === "Success"){
        navigate('/checkout/shop/order-received');
        setOrderDetail(res.data.Data);
      }
      }).catch(err => {
        
      })
  }

  function resendOTP(){
    requestOTP(SummaryData && SummaryData.ShippingAddress.Mobile);
  }

  const requestOTP = (mobile) => {
    if(mobile){
        instance.post('/account/users/get-otp', {"Mobile" : mobile})
        .then(res => {
            setTime(60);
            setIsTimerActive(true);
        })
        .catch(err => {
            // handle error
        })
    }
}

const onLogin = () => {
    const updatedOtp = code.join('');
    const firstName = SummaryData && SummaryData.ShippingAddress.FirstName;
    const lastName = SummaryData && SummaryData.ShippingAddress.LastName;
    const email = SummaryData && SummaryData.ShippingAddress.Email;
    const url = `/account/users/login-with-otp?FirstName=${firstName}&LastName=${lastName}&Email=${email}`;
    
    if(updatedOtp && code.length > 0){
        if(updatedOtp.length > 3){
            instance.post(url, {"OTP": updatedOtp})
            .then(res => {
                // mergerCart();
                localStorage.setItem('user_logged_in', true);
                setTimeout(() => {
                  PlaceOrder();
                }, 200);
            })
            .catch(err => {
                // handle error
                setValidOTP(err.response.data.Message);
            })
        }else{
          setValidOTP('Enter the 4-digit OTP code');
        }
    }else{
        setValidOTP('Enter the 4-digit OTP code');
    }
}


  const selectedPayment = SummaryData && paymentOptions ? paymentOptions.find(method => method._id === SummaryData.PaymentDetail.PaymentMethod) : '';

  useEffect(() => {
    if(!isError){
      FetchSummary();
      FetchPaymentOptions();
    }
  }, [isError]);

  const toggleCoupons = () => {
    setShowCoupons(true);
    document.querySelector( 'body' ).classList.add( 'overflow' ); // Disable scroll
    FetchCoupons();
  };

  const FetchCoupons = async() => {
    await instance.get('/account/coupons/fetch')
    .then(res => {
        setCoupons(res.data.Data);
    })
    .catch(err => {

    })
  }

  const shippingState = getState(SummaryData && SummaryData.ShippingAddress.State);
  const billingState = getState(SummaryData && SummaryData.BillingAddress.State);

  return (
    <div className="summary container mt-3">
        {SummaryData ? (
          <div className="row">
            <div className="col-lg-7">
              <div className="shipping_billing">
                <div className="shipping_info">
                  <div className="card card-dashboard">
                    <div className="card-body">
                      <h3 className="card-title">Shipping Address</h3>
                      <Link className="ms-3" to={`/checkout/shop/shipping`} state={{redirection: "false"}}>Edit <i className="icon-edit"></i></Link>
                      <div>
                        {SummaryData.ShippingAddress.FullName}{" "}
                        {SummaryData.ShippingAddress.Email},
                        <br />
                        {SummaryData.ShippingAddress.Mobile},
                        <br />
                        {SummaryData.ShippingAddress.Address1},{" "}
                        {SummaryData.ShippingAddress.Address2},
                        <br />
                        {SummaryData.ShippingAddress.City},
                        <br />
                        {shippingState.label},{" "}
                        {SummaryData.ShippingAddress.Pincode}{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="billing_info">
                  <div className="card card-dashboard">
                    <div className="card-body">
                      <h3 className="card-title">Billing Address</h3>
                      <Link className="ms-3" to={`/checkout/shop/shipping`} state={{redirection: "false"}}  >Edit <i className="icon-edit"></i></Link>
                      <div>
                        {SummaryData.BillingAddress.FullName}{" "}
                        {SummaryData.BillingAddress.Email},
                        <br />
                        {SummaryData.BillingAddress.Mobile},
                        <br />
                        {SummaryData.BillingAddress.Address1},{" "}
                        {SummaryData.BillingAddress.Address2},
                        <br />
                        {SummaryData.BillingAddress.City},
                        <br />
                        {billingState.label},{" "}
                        {SummaryData.BillingAddress.Pincode}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cart_title_btn">
                <h3>Items</h3>
                <a href={process.env.REACT_APP_SHOP_URL + "cart"} className="gradient_btn">Edit Cart</a>
              </div>
              {
                size > 767 ? 
                <table className="table table-cart table-mobile">
                  <thead>
                      <tr>
                          <th>Product</th>
                          <th>Sze</th>
                          <th>Material</th>
                          <th>Color</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Total</th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody>
                      { SummaryData.CheckoutItems.length > 0 ?
                          SummaryData.CheckoutItems.map( ( item, index ) => {
                              let size = item.Size === "SM" ? "Small" : item.Size === "MD" ? "Medium" : item.Size === "RG" ? "Regular" : "";
                              return (
                                <tr key={ index }>
                                      <td className="product-col">
                                          <div className="product">
                                              <figure className="product-media">
                                                <span className="product-image">
                                                  <img src={item.ImageURL} alt={ item.ProductName } />
                                                </span>
                                              </figure>
                                              <div className='product-disc'>
                                                  <h4 className="product-title">{ item.ProductName }</h4>
                                              </div>
                                          </div>
                                      </td>
                                      <td className="price-col">
                                          {size}
                                      </td>
                                      <td className="price-col">
                                          {item.MaterialName}
                                      </td><td className="price-col">
                                          {item.ColorName}
                                      </td>
                                      <td className="price-col">
                                          <del>₹{item.Price}</del>{" "}₹{item.SalePrice}
                                      </td>

                                      <td className="quantity-col">
                                        {item.Quantity}
                                      </td>

                                      <td className="total-col">
                                        <del>₹{item.TotalPriceInclusiveTax}</del>{" "}₹{item.TotalSalePriceInclusiveTax}
                                      </td>
                                  </tr>
                              )
                          }
                          ) :
                          <tr>
                              <td>
                                  <p className="pl-2 pt-1 pb-1"> No Products in Cart </p>
                              </td>
                          </tr>
                      }

                  </tbody>
                </table>
                :
                <div className='mobile_table_view'>
                  { SummaryData.CheckoutItems.length > 0 &&
                    SummaryData.CheckoutItems.map( ( product, index ) => {
                      let size = product.Size === "SM" ? "Small" : product.Size === "MD" ? "Medium" : product.Size === "RG" ? "Regular" : "";
                      return (
                        <div className='item' key={index}>
                          <figure className="product-media-loaded">
                              <span className="product-image">
                                  <img src={product.ImageURL } alt = { product.ProductName } />
                              </span>
                          </figure>
                          <div className='product-details'>
                              <h4 className="product-title">
                                  { product.ProductName }
                              </h4>
                              <span className='mat-col'>
                                  {product.MaterialName} - {product.ColorName} 
                              </span>
                              <span className='addional'>
                                  <span className='mat-col'><span className='strong'>Size:</span> {size}</span>
                                  <span className="out-price">₹<del>{ product.TotalPriceInclusiveTax }</del>{" "}{product.TotalSalePriceInclusiveTax}</span>
                              </span>
                              <div className='actions'>
                                <div className="dropdown">
                                  <span> <span className='strong'>Quantity:</span> { product.Quantity }</span>
                                </div>
                              </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              }
            </div>
            <aside className="col-lg-5 summary-order">
              <div className="summary summary-cart">
                <div className="summary-container">
                  <div className="summary-item">
                    <span>Shipping Options</span>
                    <div className="shipping-options">
                      <CustomSelect 
                        Standard={SummaryData.ShippingDetail.ShippingMethods.Standard} 
                        Express={SummaryData.ShippingDetail.ShippingMethods.Express}  
                        defaultValue={SummaryData.ShippingDetail.SelectedMethod.CourierId} 
                        onChange={ShippingChangeOptions} 
                        />
                    </div>
                  </div>
                  <div className="summary-item">
                    <div className="coupon_title">
                      <span className="summary-title">Payment Method</span>
                      <Link to={'/checkout/shop/shipping'} state={{redirection: "false"}}>Change Payment</Link>
                    </div>
                    <div className="payment-icon">
                      <div className="icon-title">
                        <img src={selectedPayment.Image} alt={selectedPayment.Name}/>
                        <span>{selectedPayment.Name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="summary-item">
                    <div className="coupon_title">
                      <span>Coupon</span>
                      {<Link href='/' className="gradient_btn" onClick={toggleCoupons}>View coupons</Link>}
                    </div>
                    {
                      <>
                        {showCoupons && <div className="overlay" onClick={toggleList}></div>}
                        {
                          <div className={`summary_coupons ${showCoupons ? 'active' : ''}`}>
                            <CouponsList 
                            coupons={coupons} 
                            ApplyCoupon={ApplyCoupon} 
                            toggleList={toggleList} 
                            subTotal={SummaryData.PricingDetail.CartTotalInclusiveTax} 
                            setCoupon={setCoupon}
                            />
                          </div>
                        }
                        <div className="payment-icon">
                          {SummaryData.PricingDetail.PromoCode ? <p>{SummaryData.PricingDetail.PromoCode}</p> : (
                            <input type="text" name="coupon" className="form-control" placeholder="enter coupon" onChange={(e) => setCoupon(e.target.value)} />
                          )}
                          <p className="btn btn-outline-primary" onClick={() => ApplyCoupon(SummaryData.PricingDetail.PromoCode || coupon)}>{SummaryData.PricingDetail.PromoCode ? "Remove" : "Apply"}</p>
                          
                        </div>
                        <div className="error_msg">{coupon_err}</div>
                      </>
                    }
                  </div>
                  <div className="summary-item">
                    <span>Pricing Details</span>
                    <div className="summary-primary-details">
                      <div className="price-details price-details-head">
                        <p>Subtotal</p>
                        <p>IGST(18%)</p>
                        <p>Shipping Charges</p>
                        <p>Discount</p>
                        <p>Total Price</p>
                        <p className="text-success">Total Savings</p>
                      </div>
                      <div className="price-details">
                        <p>₹{SummaryData.PricingDetail.CartTotalExclusiveTax}</p>
                        <p>₹{SummaryData.PricingDetail.GST}</p>
                        <p>₹{SummaryData.PricingDetail.ShippingCharges}</p>
                        <p>₹{SummaryData.PricingDetail.Discount || 0}</p>
                        <p>₹{SummaryData.PricingDetail.TotalPrice}</p>
                        <p className="text-success">₹{SummaryData.PricingDetail.TotalSavings}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn-order">
                  <button
                    className="gradient_btn btn-block"
                    onClick={validateUser}
                  >
                    Place Order
                  </button>
                </div>
              </div>
            </aside>
        </div>  
        ) : isError ? <div className="error_msg">{err_msg}</div> : (
          <Loader />
        )}
        {
          showPopup &&
            <div className="popup-overlay">
                <div className="popup-content">
                    <h2>Verify your Mobile </h2>
                    <p>Please enter the OTP sent to your Mobile!</p>
                    <span className="mobile_text">
                        Change number? <span className="mobile_change" onClick={mobChange}>change</span>
                    </span>
                    <div className="form-group form_otp">
                        {code.map((num, index) => (
                            <input
                                key={index}
                                id={`input-${index}`}
                                type="text"
                                value={num}
                                inputmode="numeric"
                                onChange={(e) => handleChange(e, index)}
                            />
                        ))}
                    </div>
                    <button id="login" className={`gradient_btn ${code.every(element => element === "") ? "disabled" : ""}`} onClick={onLogin}>
                        <span>Verify Otp</span>
                    </button>
                    {validOTP &&
                      <div className='forgot-link error_msg'>
                        <span>{validOTP}</span>
                      </div>
                    }
                    {
                    isTimerActive ?
                      <div className='forgot-link'>
                          <span>Resend in 00:{time}s</span>
                      </div>
                      :
                      <div className='forgot-link'>
                          <span>Didn't you get Code? </span>
                          <Link href="#" className="resend-link" onClick={resendOTP}>Resend</Link>
                      </div>
                    }
                </div>
            </div>
        }
    </div>
  );
}

export default SummaryDetails;
