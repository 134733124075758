import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AddAddress } from '../../store/Address';
import { instance } from '../../utils/ApiRequest';
import { getStates } from '../../utils';
import Loader from '../Loader';

function UpdateAddressComponent({user}) {
    
    const { id }  = useParams();
    const states = getStates();
    const [isEditLoad, setIsEditLoad] = useState(false);
    const [APIError, setAPIError] = useState("");
    const [error, setError] = useState({});
    const [formData, setFormData] = useState({
        FirstName:user.FirstName || '',
        LastName: user.LastName ||'',
        Address1: '',
        Email: user.Email || '',
        Mobile: String(user.Mobile) || '',
        Address2: '',
        City: '',
        State: '',
        Pincode: '',
        CompanyName: '',
        Default : false
    });
    const [Default, setDefault] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAddress = useCallback(async() => {
        await instance.get(`/account/addresses/${id}/fetch`)
        .then(res => {
            setFormData({
                FirstName: res.data.Data.FirstName || res.data.Data.FullName,
                LastName: res.data.Data.LastName,
                Address1: res.data.Data.Address1,
                Address2: res.data.Data.Address2,
                Email: res.data.Data.Email,
                CompanyName: res.data.Data.CompanyName,
                Mobile: String(res.data.Data.Mobile),
                City: res.data.Data.City,
                State: res.data.Data.State,
                Pincode: String(res.data.Data.Pincode),
                Default : res.data.Data.Default,
            })
            setDefault(res.data.Data.Default);
            setIsEditLoad(false);
        })
        .catch(err => {
            
            setIsEditLoad(false);
            alert("Something went wrong");
        })
    }, [id])
    useEffect(() => {
        if(id){
            setIsEditLoad(true);
            getAddress();
        }
    }, [id, getAddress])

    function HandleChangeDefault(event) {
        const {checked, value}  = event.target;
        if(value === "Default"){
            setFormData({
                ...formData,
                Default : checked
            })
        }
    }

    function closeModal () {
        navigate('/addresses');
    }

    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const numberRegex = /^[0-9]*$/;
    // Validation function
    const validateFields = (data) => {
        const errors = {};

        // Loop through form data and validate fields
        for (const key in data) {
            if (!data[key] && key !== 'CompanyName' && key !== 'Address2' && key !== 'GSTIN') {
                errors[key] = 'This field is required';
            }
            if (key === 'Email' && data[key] && !emailRegex.test(data[key])) {
                errors['Email'] = 'Please enter a valid email';
            }
            if (key === 'Mobile' && (data[key].length > 10 || data[key].length < 10)) {
                errors['Mobile'] = 'Please enter a valid mobile';
            }
            if (key === 'Pincode' && (data[key].length > 6 || data[key].length < 6)) {
                errors['Pincode'] = 'Please enter a valid pincode';
            }
        }
        
        return errors;
    };

    const handleChangeFields = (e) => {
        const { name, value } = e.target;

        // Allow only numbers for Mobile and Pincode fields
        if ((name === 'Mobile' || name === 'Pincode') && !numberRegex.test(value)) {
            return;
        }
        setFormData(prev => {
            const newFormData = { ...prev, [name]: value };
            const errorMessage = validateFields(newFormData);
            setError(errorMessage);

            return newFormData;
        });
    }
    const onSubmit = async(e) => {
        e.preventDefault();

        const addressErrors = validateFields(formData);
        // console.log(formData);
        
        // return;
        if (Object.keys(addressErrors).length) {
            setError(addressErrors);
            // Find the first error field
            const firstErrorFieldName = Object.keys(addressErrors).length
            ? Object.keys(addressErrors)[0]
            : null;

            // Find the first error field (input or select)
            const firstErrorField = document.querySelector(`input[name="${firstErrorFieldName}"], select[name="${firstErrorFieldName}"]`);
            // console.log(firstErrorField);
            
            if (firstErrorField) {
                const offset = 100; // Adjust this value as needed for spacing
                const rect = firstErrorField.getBoundingClientRect();
                const scrollToPosition = window.scrollY + rect.top - offset;

                window.scrollTo({
                    top: scrollToPosition,
                    behavior: 'smooth'
                });
                firstErrorField.focus();
                return;
            }
        }

        if(id !== undefined){
            await instance.put(`/account/addresses/${id}/update`, formData)
            .then(res => {
                setTimeout(() => {
                    navigate('/addresses');
                }, 400);
            })
            .catch(err => {
                
                setAPIError(err.response.data.Message);
            })
        }else{
            dispatch(AddAddress(formData))
            .unwrap()
            .then((res) => {
                navigate('/addresses');
            })
            .catch(err => {
                setAPIError(err.response.data.Message)
            });
        }

        setTimeout(() => {
            setAPIError('');
        }, 3000);
    }
  return (
    <div className='address_update address form-box shipping_address'>
        { id ? <h3>Update Address</h3> : <h3>Create Address</h3> }
        {!isEditLoad ? 
            <form onSubmit={onSubmit} className='mt-2'>
                <div className="row">
                    <div className="col-sm-6">
                        <label>First Name *</label>
                        <input type="text" name="FirstName" placeholder='Enter your first name' className={`form-control ${error.FirstName ? "border-danger" : ""}`} value={formData.FirstName} onChange={handleChangeFields}/>
                    </div>
                    <div className="col-sm-6">
                        <label>Last Name *</label>
                        <input type="text" name="LastName" placeholder='Enter your last name' className={`form-control ${error.LastName ? "border-danger" : ""}`} value={formData.LastName}  onChange={handleChangeFields}/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <label>Company Name (optional)</label>
                        <input type="text" name="CompanyName" placeholder='Enter your Company Name' className={`form-control ${error.CompanyName ? "border-danger" : ""}`} value={formData.CompanyName}  onChange={handleChangeFields}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <label>Email address *</label>
                        <input type="email" name='Email' placeholder='Enter your email' className={`form-control ${error.Email ? "border-danger" : ""}`} value={formData.Email} onChange={handleChangeFields}/>
                    </div>
                    <div className="col-sm-6">
                        <label>Mobile *</label>
                        <input type="tel" name='Mobile' maxlength="10" placeholder='Enter your mobile number' className={`form-control ${error.Mobile ? "border-danger" : ""}`} value={formData.Mobile} onChange={handleChangeFields}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <label>Address *</label>
                        <input type="text" name="Address1" placeholder='Plot no:04/345...' className={`form-control ${error.Address1 ? "border-danger" : ""}`} value={formData.Address1}  onChange={handleChangeFields}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <label>Landmark (optional)</label>
                        <input type="text" name="Address2" placeholder='Landmark...' className={`form-control ${error.Address2 ? "border-danger" : ""}`} value={formData.Address2} onChange={handleChangeFields}/>
                    </div>
                    <div className="col-sm-6 select-custom">
                        <label>State *</label>
                        <select className={`form-control form-select-sm ${error.Address1 ? "border-danger" : ""}`}  name='State' id="inputState" defaultValue={formData.State} onChange={handleChangeFields}>
                            <option value=''>Select the state</option>
                        {
                            states.map((state, index) => (
                                <option value={state.value} key={index}>{state.label}</option>
                            ))
                        }
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <label>City *</label>
                        <input type="text" name='City' placeholder='Enter your city' className={`form-control ${error.City ? "border-danger" : ""}`} value={formData.City} onChange={handleChangeFields}/>
                    </div>
                    <div className="col-sm-6">
                        <label>Pincode *</label>
                        <input type="tel" name='Pincode' maxlength="6" placeholder='Enter your pincode' className={`form-control ${error.Pincode ? "border-danger" : ""}`} value={formData.Pincode} onChange={handleChangeFields}/>
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className={`col-sm-6 billing_shiping ${Default === true ? 'disabled' : ''}`}>
                        <label>
                            <input type="checkbox" className="main_uni_courses" defaultChecked={Default === true} value="Default" id={`default_billing`} onChange={HandleChangeDefault}/>
                            <span htmlFor={`default_billing`}>Make Default</span>
                        </label>
                    </div>
                </div>
                {APIError && <span className='text-danger'>{APIError}</span>}
                <div className='action-buttons'>
                    <button type="submit" className="gradient_btn">
                       {!id ? "Add Address" : "Update Address"}
                    </button>
                    <button onClick={closeModal} className="gradient_btn">
                        Cancel
                    </button>
                </div>
            </form>
        : <Loader />}
    </div>
  )
}

export default UpdateAddressComponent;