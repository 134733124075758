import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import './Components/fonts-ocean.min.css'
import './Components/chillax.css';
import './Components/bootstrap-icons.min.css';
import Dashboard from './Components/Dashboard/Dashboard';
import NewHeader from './Components/Header/NewHeader';
import { Navigate, Route, Routes, Link, useLocation } from 'react-router-dom';
import Signup from './Components/Login/Signup';
import PendingProfile from './Components/Login/PendingProfile';
import {instance } from './utils/ApiRequest';
import Shop from './Components/Checkout/Shop/Shop';
import OrderSuccss from './Components/Checkout/Orders/OrderSuccss';
import PrivateRoutes from './Components/Route/PrivateRoutes';
import RequestOrderDetails from './Components/Checkout/Orders/RequestOrderDetails';
import Footer from './Components/Footer';
import Print from './Components/Checkout/Print/Print';
import Loader from './Components/Loader';
import Maintenance from './Components/Maintanance/Maintenance';
import MaintananceRoute from './Components/Route/MaintenanceRoute';
import HomeRoute from './Components/Route/HomeRoute';
import useSize from './Components/Size/useSize';
import MobileMenu from './Components/Header/mobile-menu';

function App() {
  // var scrollTop;
  const scrollTop = useRef(0);
  const user_log = localStorage.getItem('user_logged_in');
  const [isValidSession, setIsValidSession] = useState(false);
  const [userData, setUserData] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(null);
  const [isLoggedIn, setIsLoggedIn] =  useState(user_log  === null ? false : user_log);
  const [OrderDetail, setOrderDetail] = useState(null);

  const location = useLocation();
  const size = useSize();

  // active components by pathname
  const dashboard = location.pathname === '/dashboard';
  const account = location.pathname === '/account';
  const address = location.pathname === '/address';
  const orders = location.pathname === '/orders/shop' || location.pathname === '/orders/print';
  const view_order = location.pathname.includes('/shop/view-order/') || location.pathname.includes('/print/view-order/') ;
  const coupons = location.pathname === '/coupons';
  const address_edit = location.pathname.includes('/address/edit');
  const address_add = location.pathname === '/address/add';

  useEffect(() => {
    window.addEventListener( 'scroll', scrollHandler, false );
    scrollTop.current = document.querySelector( '#scroll-top' );
    window.addEventListener("resize", resizeHandle);
    resizeHandle();
    return () => {
      window.removeEventListener("resize", resizeHandle);
    };
  }, [])

//scroll top 
function toScrollTop () {
  window.scrollTo( {
      top: 0,
      behavior: 'smooth'
  } );
}
function scrollHandler () {
  if ( window.scrollY >= 400 ) {
      scrollTop.current.classList.add( 'show' );
  } else {
      scrollTop.current.classList.remove( 'show' );
  }
}
//end
  //iframe mobile view
function resizeHandle() {
  if (document.querySelector("body").offsetWidth < 992) setToggle(true);
  else setToggle(false);
}

  //validate session cookie
  const  validateSessionCookie = useCallback(() => {
    instance.get('/account/users/validate-session')
    .then(res => {
      if(res.data.Data.User !== null){
        setIsLoggedIn(true);
        localStorage.setItem('user_logged_in', true);
        setUserData(res.data.Data.User)
      }else{
        setIsLoggedIn(false);
        localStorage.removeItem('user_logged_in');
      }
      setIsValidSession(true);
    }).catch(err => {
      instance.post('/account/users/create-session')
      .then(res => {
        validateSessionCookie();
      }).catch(err => {
      })
      localStorage.removeItem('user_logged_in');
    })
  }, [])

// maintenance mode
const MaintananceMode = useCallback(() => {
  instance.get('/account/maintenance')
  .then(res => {
    if(res.status === 200){
      setIsMaintenance(res.data.Data);
      // validateSessionCookie();
      setIsValidSession(true);
    }
  })
  .catch(err => {
    validateSessionCookie();
  })
},[validateSessionCookie])


  useEffect(() => {
      MaintananceMode();
  }, [MaintananceMode]);
  
  function hideMobileMenu () {
    document.querySelector( 'body' ).classList.remove( 'mmenu-active' );
  }

  return (
    <div className='site'>
        { isValidSession ? 
      <>
        {isMaintenance == null && <NewHeader isLoggedIn={isLoggedIn} toggle={toggle}/>}
        <div className="page-content">
          <Routes>
            <Route path='/maintenance' element={<Maintenance isMaintenance={isMaintenance} />} />
            <Route element={<MaintananceRoute isMaintenance={isMaintenance} />}>
              <Route path='*' element={<HomeRoute isMaintenance={isMaintenance} />} />
              <Route path='/' element={<HomeRoute isMaintenance={isMaintenance}e />} />
              <Route path='/login' element={<Signup isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>}/>
              <Route path='/pending-profile' element={<PendingProfile />} />
              <Route element={<PrivateRoutes isLoggedIn={isLoggedIn} />}>
                <Route path='/dashboard' element={<Dashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>} />
                <Route path='/orders/print' element={<Dashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                <Route path='/orders' element={<Navigate to='/orders/shop' />} />
                <Route path='/orders/shop' element={<Dashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                <Route path='/orders/shop/:id' element={<Dashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                <Route path='/orders/print/:id' element={<Dashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                <Route path='/coupons' element={<Dashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                <Route path='/notification-preferences' element={<Dashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                <Route path='/addresses' element={<Dashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                <Route path='/address/add' element={<Dashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                <Route path='/address/edit/:id' element={<Dashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                <Route path='/profile' element={<Dashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                {/* <Route path='/change-password' element={ <Dashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} /> }/> */}
              </Route>
              <Route path='/checkout/shop/' element={<Navigate to='/checkout/shop/shipping' />} />
              <Route path='/checkout/shop/shipping' element={<Shop user={userData != null && userData} />} />
              <Route path='/checkout/shop/billing' element={<Shop user={userData != null && userData} />} />
              <Route path='/checkout/shop/payment' element={<Shop />} />
              <Route path='/checkout/shop/summary' element={<Shop setOrderDetail={setOrderDetail} />} />
              <Route path='/checkout/print/' element={<Navigate to='/checkout/print/shipping' />} />
              <Route path='/checkout/print/shipping' element={<Print user={userData != null && userData} />} />
              <Route path='/checkout/print/billing' element={<Print user={userData != null && userData} />} />
              <Route path='/checkout/print/payment' element={<Print />} />
              <Route path='/checkout/print/summary' element={<Print setOrderDetail={setOrderDetail} />} />
              <Route path='/checkout/shop/order-received' element={<OrderSuccss  OrderDetail={OrderDetail && OrderDetail}/>} />
              <Route path='/checkout/print/order-received' element={<OrderSuccss  OrderDetail={OrderDetail && OrderDetail}/>} />
              <Route path='track-order' element={<RequestOrderDetails />} />
            </Route>
          </Routes>
          {isMaintenance == null && <Footer />}
        </div>
      </>  :
      <Loader />
      }
      <div id='loader'>
        <Loader />
      </div>
      <div className="mobile-menu-overlay" onClick={ hideMobileMenu }></div>
      {
        size < 992 &&
        <MobileMenu />
      }
      <button id="scroll-top" title="Back to top" onClick={ toScrollTop }>
        <i className="icon-arrow-up"></i>
      </button>
      {
        isLoggedIn &&
        <div className="footer-tik">
          <ul>
            <li>
              <Link to={process.env.REACT_APP_HOME_URL} className={dashboard ? "wishlist-link active" : "wishlist-link"}>
                <div className="icon">
                  <i className="icon-home"></i>
                  <span>Home</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/prifle" className={account ? "wishlist-link active" : "wishlist-link"}>
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                  </svg>
                  <span>account</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/coupons" className={coupons ? "wishlist-link active" : "wishlist-link"}>
                <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tags-fill" viewBox="0 0 16 16">
                      <path d="M2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586zm3.5 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
                      <path d="M1.293 7.793A1 1 0 0 1 1 7.086V2a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l.043-.043z"/>
                    </svg>
                    <span>Coupons</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/addresses" className={(address || address_add || address_edit) ? "wishlist-link active" : "wishlist-link"}>
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pin-map" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8z"/>
                    <path fillRule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>
                  </svg>
                  <span>Address</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/orders/shop" className={(orders || view_order) ? "wishlist-link active" : "wishlist-link"}>
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bag-check-fill" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0m-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                  </svg>
                  <span>Orders</span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      }
    </div>
  );
}

export default App;
