import React, { useCallback, useEffect, useState } from "react";
import "./payment.css";
import { instance } from "../../../utils/ApiRequest";
import {useLocation, useNavigate } from "react-router-dom";

function PrintPayment({checkoutDetails}) {
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState();

  const selectedPayment = checkoutDetails.PaymentDetail && checkoutDetails.PaymentDetail.PaymentMethod;
  
  const navigate = useNavigate();

  const { state } = useLocation();

  const FetchPaymentOptions = useCallback(async () => {
    await instance
      .get("/account/checkout/print/payment-methods")
      .then((res) => {
        if (res.data.Status === "Success") {
          setSelectedOption(selectedPayment);
          setPaymentOptions(res.data.Data);
        }
      })
      .catch((err) => {
      });
  },[selectedPayment]);

  function selectOption(value) {
    setSelectedOption(value);
  };

  function handleBack(){
    navigate('/checkout/print/billing', { state: {redirection: "false"} });
  }

  const handleUpdatePayment = async() =>{
    if(selectedOption){
      await instance.put('/account/checkout/print/update-payment-method', {"PaymentMethodId": selectedOption})
      .then(res => {
        if(res.data.Status === "Success"){
          navigate('/checkout/print/summary', { state: {redirection: "false"} });
        }
      })
      .catch(err => {
      })
    }
  }

  useEffect(() => {
    if(state == null){
      if(checkoutDetails.PaymentDetail){
          navigate('/checkout/shop/summary');
      }else if(checkoutDetails.BillingAddress){
          navigate('/checkout/shop/payment');
      }else if (checkoutDetails.ShippingAddress) {
          navigate('/checkout/shop/billing');
      }
  }
    FetchPaymentOptions();
  }, [state, checkoutDetails.BillingAddress, checkoutDetails.ShippingAddress, checkoutDetails.PaymentDetail, navigate, FetchPaymentOptions]);

  return (
    <div className="payment-options mt-3 address">
      <div className="payment-type">
        <h4>Choose payment method below</h4>
        <div className="types flex justify-space-between">
          {!paymentOptions && checkoutDetails ? (
            <div>Loading</div>
          ) : (
            paymentOptions &&
            paymentOptions.map((option, index) => {
              return (
                <div className={selectedOption === option._id ? "type selected" : "type"} key={index} onClick={() => selectOption(option._id)}>
                  <div className="icon">
                    <img src={option.Image} alt={option.Name}/>
                  </div>
                  <div className="text">
                    <strong>{option.Name}</strong>
                    <p>{option.Description}</p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <div className="float-end">
        <button type="button" className="btn btn-outline-primary" onClick={handleBack}>
            <i className="icon-long-arrow-left"></i>
            <span>Back</span>
        </button>
        <button type="button" className="btn btn-outline-primary ms-3" onClick={handleUpdatePayment}>
            <span>Proceed</span>
        </button>
      </div>
    </div>
  );
}

export default PrintPayment;
