import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

function PrivateRoutes({isLoggedIn}) {
  const targetLocation = window.location;
  return (
    isLoggedIn ? <Outlet /> : <Navigate to={`/login?redirectTo=${targetLocation}`} />
  )
}

export default PrivateRoutes