import React from 'react'
import { formatDate } from '../../../utils';

function CouponsList({coupons, ApplyCoupon, toggleList, subTotal }) {
    const CouponCode = (code) => {
        setTimeout(() => {
            ApplyCoupon(code);
            toggleList();
        }, 300);
    }
  return (
    <aside className="sidebar">
        <div className="coupon_close">
            <h2>Available Coupons</h2>
            <span onClick={toggleList}>X</span>
        </div>
        <ul className="coupons-list">
            {coupons.length > 0 && coupons.map((coupon, index) => {
                const differenceTotal = coupon.MinCartValue - subTotal;
                return(
                <li key={index} className="coupon-item">
                    <div className={`coupon-details ${Math.sign(differenceTotal) === 1 || coupon.Expired ? 'disabled' : ''}`}>
                        {
                        coupon.DiscountType === 'percentage' ? 
                        <h3>
                            <small>flat</small> {coupon.Value}% <small className="ri">OFF</small>
                        </h3>
                        :
                        <h3>
                            <small>flat</small> ₹{coupon.Value} <small className="ri">OFF</small>
                        </h3>
                        }
                        {
                        <p>{coupon.Description}</p>
                        }
                        <p className='expiring'>
                            <svg
                                className="svgalign mr-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                >
                                <g fill="#7B8B8E">
                                    <path
                                    d="M12.4811046,3.96969444 C11.7957157,2.37177778 10.6310261,1.2096585 9.01976961,0.522464052 C8.21444935,0.179004902 7.3707451,0.00356862745 6.48931536,0.00356862745 C5.60803431,0.00356862745 4.77055392,0.177348039 3.96612582,0.522464052 C2.36820915,1.2078317 1.19835784,2.36920752 0.51175817,3.96969444 C0.167704248,4.77153105 0,5.6115817 0,6.49286275 C0,7.37431373 0.167704248,8.2213317 0.51175817,9.02333824 C1.19835784,10.6238252 2.36835784,11.7921683 3.96612582,12.4777059 C4.77070261,12.8228219 5.60801307,12.9964314 6.48931536,12.9964314 C7.37061765,12.9964314 8.21444935,12.8209951 9.01964216,12.4777059 C10.6308987,11.7904902 11.7955882,10.6211062 12.4811258,9.02333824 C12.8262206,8.21876144 13,7.37431373 13,6.49286275 C13,5.6115817 12.8263693,4.77425 12.4811046,3.96969444 Z M11.4434837,9.36452451 C10.9332337,10.2395817 10.2342075,10.9337859 9.35381863,11.4470523 C8.47876144,11.9573023 7.51996895,12.2146258 6.48946405,12.2146258 C5.71477451,12.2146258 4.97555882,12.0654232 4.27181699,11.759732 C3.56809641,11.4541895 2.9604951,11.0525065 2.45222059,10.5443807 C1.94394608,10.0361062 1.53939542,9.42257843 1.23689052,8.71764706 C0.935277778,8.01528595 0.789112745,7.26772222 0.789112745,6.49288399 C0.789112745,5.46223039 1.0423366,4.50830229 1.54971895,3.63566667 C2.05890686,2.75984477 2.7580817,2.06489706 3.63224673,1.55311765 C4.50626307,1.04135948 5.4588317,0.785395425 6.48931536,0.785395425 C7.51996895,0.785395425 8.4785915,1.04286765 9.35366993,1.55311765 C10.2340588,2.06640523 10.9315768,2.7613317 11.443335,3.63564542 C11.9550931,4.50981046 12.2109085,5.46223039 12.2109085,6.49286275 C12.2109085,7.52353758 11.9567712,8.48396569 11.4434837,9.36452451 Z"
                                    ></path>
                                    <path
                                    d="M6.90159804,6.43601961 L6.90159804,2.75419444 C6.90159804,2.53387418 6.70963562,2.35616503 6.48929412,2.35616503 C6.28322712,2.35616503 6.09838072,2.53387418 6.09838072,2.75419444 L6.09838072,6.49286275 C6.09838072,6.5 6.11263399,6.52850654 6.11263399,6.54974837 C6.09838072,6.67770915 6.13402451,6.7843219 6.21924673,6.86969281 L8.34455556,8.99485294 C8.48676961,9.13706699 8.74970098,9.13706699 8.89180882,8.99485294 C9.05524346,8.83139706 9.0444951,8.59423203 8.89180882,8.44046242 L6.90159804,6.43601961 Z"
                                    ></path>
                                </g>
                            </svg>
                            {formatDate(coupon.ExpiryDate)}
                        </p>
                        <span className='apply_btn' onClick={() => CouponCode(coupon.Code)}>Apply</span>
                    </div>
                    <span className='warning_msg'>
                        {Math.sign(differenceTotal) === 1 && 'Add ₹' + differenceTotal + ' more to apply the coupon.'}
                    </span>
                </li>
                )
            })}
        </ul>
    </aside>
  )
}

export default CouponsList