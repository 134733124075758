import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getStates } from '../../../utils';

function BillingForm({onSubmit, updateFields, error, handleChangeFields, setSameAsShipping, handleSameAsShiiping, billing}) {
    const location = useLocation();
    const states = getStates();
    const navigate = useNavigate();

    const PrevBtn = () => {
        if(location.pathname.includes('/shop')){
            navigate('/checkout/shop/shipping', { state: {redirection: "false"} })
        }else{
            navigate('/checkout/print/shipping', { state: {redirection: "false"} })
        }
    }
    return (
        <>
        <label className='billing_shiping'>
            <input type="checkbox" className="main_uni_courses" name='same_as_shipping' id="same_as_shipping" onChange={handleSameAsShiiping}/>
            <span htmlFor="same_as_shipping" style={{marginTop:'-20px'}}>Set same as Shipping Details</span>
        </label>
            <form onSubmit={onSubmit} style={{width:'90%'}}>
                <div className="row">
                    <div className="col-sm-6">
                        <label>Full Name *</label>
                        <input type="text" name="FullName" className="form-control" disabled={setSameAsShipping} value={updateFields.FullName}  onChange={handleChangeFields}/>
                    {error.FullName && <span className='text-danger'>{error.FullName}</span>}
                    </div>
                    <div className="col-sm-6">
                        <label>Email address *</label>
                        <input type="email" name='Email' className="form-control" disabled={setSameAsShipping} value={updateFields.Email} onChange={handleChangeFields}/>
                    {error.Email && <span className='text-danger'>{error.Email}</span>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <label>Mobile *</label>
                        <input type="tel" name='Mobile' className="form-control" disabled={setSameAsShipping} value={updateFields.Mobile} onChange={handleChangeFields}/>
                    {error.Mobile && <span className='text-danger'>{error.Mobile}</span>}
                    </div>
                    <div className="col-sm-6">
                        <label>Company (optional)</label>
                        <input type="tel" name='CompanyName' className="form-control" disabled={setSameAsShipping} value={updateFields.CompanyName || ""} onChange={handleChangeFields}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <label>Address1 *</label>
                        <input type="text" name="Address1" className="form-control" disabled={setSameAsShipping} value={updateFields.Address1}  onChange={handleChangeFields}/>
                    {error.Address1 && <span className='text-danger'>{error.Address1}</span>}
                    </div>
                    <div className="col-sm-6">
                        <label>Address2 (optional)</label>
                        <input type="text" name="Address2" className="form-control" disabled={setSameAsShipping} value={updateFields.Address2} onChange={handleChangeFields}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 select-custom">
                        <label>State *</label>
                        {
                        updateFields.State && 
                        <select className="form-control form-select-sm"  name='State' id="inputState" defaultValue={updateFields.State} onChange={handleChangeFields}>
                            {
                                states.map((state, index) => (
                                    <option value={state.value} key={index}>{state.label}</option>
                            ))
                            }
                        </select>
                        }
                        {
                            billing == null && updateFields.State === '' &&
                            <select className="form-control form-select-sm"  name='State' id="inputState" onChange={handleChangeFields}>
                                {
                                    states.map((state, index) => (
                                        <option value={state.value} key={index}>{state.label}</option>
                                    ))
                                }
                            </select>
                        }
                        {error.State && <span className='text-danger'>{error.State}</span>}
                    </div>
                    <div className="col-sm-6">
                        <label>City *</label>
                        <input type="text" name='City' className="form-control" disabled={setSameAsShipping} value={updateFields.City} onChange={handleChangeFields}/>
                    {error.City && <span className='text-danger'>{error.City}</span>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <label>Pincode *</label>
                        <input type="tel" name='Pincode' className="form-control" disabled={setSameAsShipping} value={updateFields.Pincode} onChange={handleChangeFields}/>
                    {error.Pincode && <span className='text-danger'>{error.Pincode}</span>}
                    </div>
                </div>
                <div className='float-end'>
                    <span onClick={PrevBtn} className="gradient_btn">
                        <span>Back</span>
                    </span>
                    <button type="submit" className="gradient_btn ms-3">
                        <span>Proceed</span>
                    </button>
                </div>
            </form>
        </>
      )
}

export default BillingForm