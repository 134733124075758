import React, { useEffect, useState } from "react";
import "./summary.css";
import { instance } from "../../../utils/ApiRequest";
import { Link, useNavigate } from "react-router-dom";
import useSize from "../../Size/useSize";
import CustomSelect from "./CustomShipping";
import CouponsList from "./CouponsList";
import Loader from "../../Loader";

function PrintSummary({setOrderDetail,}) {
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [SummaryData, setSummaryData] = useState(null);
  const [isError, setIsError] = useState(false);
  const [err_msg, set_err_msg] = useState('');
  const [coupon_err, set_coupon_err] = useState('');
  const [coupons, setCoupons] = useState([]);
  const [coupon, setCoupon] = useState('');
  const [showCoupons, setShowCoupons] = useState(false);

  const navigate = useNavigate();
  const size = useSize();
  const isLoggedIn = localStorage.getItem('user_logged_in');
  async function FetchSummary() {
    await instance
      .get("/account/checkout/print/summary")
      .then((res) => {
        if (res.data.Status === "Success") {
          setSummaryData(res.data.Data);
        }
      })
      .catch((err) => {
        
        setIsError(true);
        set_err_msg(err.response.data.Message);
      });
  }
  const toggleList = () => {
    setShowCoupons(false);
    document.querySelector( 'body' ).classList.remove( 'overflow' ); // enable scroll
  };

  async function ApplyCoupon(coupon){
    const data = SummaryData.PricingDetail.PromoCode ? {} : {
      "CouponCode": coupon
    };
    if(SummaryData.PricingDetail.PromoCode){
      await instance.put('/account/checkout/print/remove-coupon')
      .then(res => {
        if(res.data.Status === "Success"){
          FetchSummary();
        }
        
      }).catch(err => {
        
        set_coupon_err(err.response.data.Message);
      })
    }else{
      await instance.put('/account/checkout/print/apply-coupon',data)
      .then(res => {
        if(res.data.Status === "Success"){
          FetchSummary();
        }
        
      }).catch(err => {
        
        set_coupon_err(err.response.data.Message);
      })
    }

    setTimeout(() => {
      set_coupon_err('')
    }, 3000);
  }

  async function ShippingChangeOptions(value) {
    if(value){
      await instance.put('/account/checkout/print/update-shipping-method',{
        "CourierId": value
      })
      .then(res => {
        if(res.data.Status === "Success"){
          FetchSummary();
        }
        
      }).catch(err => {
        
      })
  }
  }
  const FetchPaymentOptions = async () => {
    await instance
      .get("/account/checkout/print/payment-methods")
      .then((res) => {
        if (res.data.Status === "Success") {
          setPaymentOptions(res.data.Data);
        }
      })
      .catch((err) => {
        
      });
  };

  async function PlaceOrder(){
    await instance.post('/account/orders/print/place')
    .then(res => {
      if(res.data.Status === "Success"){
        navigate('/checkout/print/order-received');
        setOrderDetail(res.data.Data);
      }
      }).catch(err => {
        
      })
  }

  const selectedPayment = SummaryData && paymentOptions ? paymentOptions.find(method => method._id === SummaryData.PaymentDetail.PaymentMethod) : '';

  useEffect(() => {
    if(!isError){
      FetchSummary();
      FetchPaymentOptions();
    }
  }, [isError]);

  const toggleCoupons = () => {
    setShowCoupons(true);
    document.querySelector( 'body' ).classList.add( 'overflow' ); // Disable scroll
    FetchCoupons();
  };

  const FetchCoupons = async() => {
    await instance.get('/account/coupons/fetch')
    .then(res => {
        setCoupons(res.data.Data);
    })
    .catch(err => {

    })
  }

  return (
    <div className="summary container mt-3">
        {SummaryData ? (
          <div className="row">
            <div className="col-lg-7">
              <div className="shipping_billing">
                <div className="shipping_info">
                  <div className="card card-dashboard">
                    <div className="card-body">
                      <h3 className="card-title">Shipping Address</h3>
                      <Link className="ms-3" to={`/checkout/print/shipping`} state={{redirection: "false"}} >Edit <i className="icon-edit"></i></Link>
                      <div>
                        {SummaryData.BillingAddress.FullName}{" "}
                        {SummaryData.ShippingAddress.Address1},{" "}
                        {SummaryData.ShippingAddress.Address2},
                        {SummaryData.ShippingAddress.City}
                        <br />
                        {SummaryData.ShippingAddress.State}{" "}
                        {SummaryData.ShippingAddress.Pincode}{" "}
                        {SummaryData.ShippingAddress.Mobile}
                        <br />
                        {SummaryData.ShippingAddress.Email}
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="billing_info">
                  <div className="card card-dashboard">
                    <div className="card-body">
                      <h3 className="card-title">Billing Address</h3>
                      <Link className="ms-3" to={`/checkout/print/shipping`} state={{redirection: "false"}} >Edit <i className="icon-edit"></i></Link>
                      <div>
                        {SummaryData.BillingAddress.FullName}{" "}
                        {SummaryData.BillingAddress.Address1},{" "}
                        {SummaryData.BillingAddress.Address2},
                        {SummaryData.BillingAddress.City}
                        <br />
                        {SummaryData.BillingAddress.State}{" "}
                        {SummaryData.BillingAddress.Pincode}{" "}
                        {SummaryData.BillingAddress.Mobile}
                        <br />
                        {SummaryData.BillingAddress.Email}
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cart_title_btn">
                <h3>Items</h3>
                <a href={process.env.REACT_APP_PRINT_URL + "cart"} className="btn btn-outline-primary">Edit Cart</a>
              </div>
              {
                  size > 767 ? 
                    <table className="table table-cart table-mobile">
                      <thead>
                          <tr>
                              <th>QuoteNumber</th>
                              <th>Material</th>
                              <th>Color</th>
                              <th>Price</th>
                              <th>Quantity</th>
                              <th>Total</th>
                              <th></th>
                          </tr>
                      </thead>
                      <tbody>
                          { SummaryData.CheckoutItems.length > 0 ?
                              SummaryData.CheckoutItems.map( ( item, index ) => {
                                  return (
                                      <tr key={ index }>
                                          <td className="product-col">
                                              <div className="product">
                                                  <figure className="product-media">
                                                    <a className="product-image" href="/product/65e1ddd44a7cf7915c5e95af/RGGDGR10001">
                                                      <img src={item.SnapShotURL} alt={ item.QuoteNumber } />
                                                    </a>
                                                  </figure>
                                                  <div className='product-disc'>
                                                      <h4 className="product-title">{ item.QuoteNumber }</h4>
                                                  </div>
                                              </div>
                                          </td>
                                          <td className="price-col">
                                              {item.MaterialName}
                                          </td><td className="price-col">
                                              {item.ColorName}
                                          </td>
                                          <td className="price-col">
                                              ₹{item.Price}
                                          </td>

                                          <td className="quantity-col">
                                            {item.Quantity}
                                          </td>

                                          <td className="total-col">
                                          ₹{item.TotalPriceInclusiveTax}
                                          </td>
                                      </tr>
                                  )
                              }
                              ) :
                              <tr>
                                  <td>
                                      <p className="pl-2 pt-1 pb-1"> No Products in Cart </p>
                                  </td>
                              </tr>
                          }

                      </tbody>
                    </table>
                  :
                  <div className='mobile_table_view'>
                  { SummaryData.CheckoutItems.length > 0 &&
                    SummaryData.CheckoutItems.map( ( product, index ) => {
                      return (
                        <div className='item' key={index}>
                          <figure className="product-media-loaded">
                              <Link href={ `/product/${product.SKU}` } className="product-image">
                                  <img src={product.SnapShotURL } alt = { product.QuoteNumber } />
                              </Link>
                          </figure>
                          <div className='product-details'>
                              <h4 className="product-title">
                                  <Link href={ `/product/${product.SKU}` }>{ product.QuoteNumber }</Link>
                              </h4>
                              <span className='mat-col'>
                                  {product.MaterialName} - {product.ColorName} 
                              </span>
                              <span className='addional'>
                                  <span className="out-price">₹<del>{ product.TotalPriceInclusiveTax }</del>{" "}{product.TotalSalePriceInclusiveTax}</span>
                              </span>
                              <div className='actions'>
                                <div className="dropdown">
                                  <span> <span className='strong'>Quantity:</span> { product.Quantity }</span>
                                </div>
                              </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              }
            </div>
            <aside className="col-lg-5 summary-order">
              <div className="summary summary-cart">
                <div className="summary-container">
                  <div className="summary-item">
                    <span>Shipping Options</span>
                    <div className="shipping-options">
                    <CustomSelect 
                        Standard={SummaryData.ShippingDetail.ShippingMethods.Standard} 
                        Express={SummaryData.ShippingDetail.ShippingMethods.Express}  
                        defaultValue={SummaryData.ShippingDetail.SelectedMethod.CourierId} 
                        onChange={ShippingChangeOptions} 
                      />
                    </div>
                  </div>
                  <div className="summary-item">
                    <div className="coupon_title">
                      <span className="summary-title">Payment Method</span>
                      <Link to={'/checkout/print/payment'} state={{redirection: "false"}} >Change Payment</Link>
                    </div>
                    <div className="payment-icon">
                      <div className="icon-title">
                        <img src={selectedPayment.Image} alt={selectedPayment.Name}/>
                        <span>{selectedPayment.Name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="summary-item">
                    <div className="coupon_title">
                      <span>Coupon</span>
                      {isLoggedIn && <Link href='/coupons' className="gradient_btn" onClick={toggleCoupons}>View coupons</Link>}
                    </div>
                    <div className="payment-icon">
                    {
                      !isLoggedIn ?
                      <div className="no_user_coupon">
                          <div className='reply-review d-flex'>
                              <p>please</p>&nbsp; &nbsp;
                              <Link to={process.env.REACT_APP_ACCOUNT_URL + 'login?redirectTo=' + window.location.href} className='login'>login</Link>&nbsp; &nbsp;
                              <span>/</span>&nbsp; &nbsp;
                              <Link to={process.env.REACT_APP_ACCOUNT_URL + 'login?redirectTo=' + window.location.href} className='signup'>signup</Link>
                              &nbsp; &nbsp;<span>to apply coupon</span>
                          </div>
                      </div>
                      :
                      <>
                        {showCoupons && <div className="overlay" onClick={toggleList}></div>}
                        {
                          <div className={`summary_coupons ${showCoupons ? 'active' : ''}`}>
                            <CouponsList 
                            coupons={coupons} 
                            ApplyCoupon={ApplyCoupon} 
                            toggleList={toggleList} 
                            subTotal={SummaryData.PricingDetail.CartTotalInclusiveTax} 
                            setCoupon={setCoupon}
                            />
                          </div>
                        }
                        <div className="payment-icon">
                          {SummaryData.PricingDetail.PromoCode ? <p>{SummaryData.PricingDetail.PromoCode}</p> : (
                            <input type="text" name="coupon" className="form-control" placeholder="enter coupon" onChange={(e) => setCoupon(e.target.value)} />
                          )}
                          <p className="btn btn-outline-primary" onClick={() => ApplyCoupon(SummaryData.PricingDetail.PromoCode || coupon)}>{SummaryData.PricingDetail.PromoCode ? "Remove" : "Apply"}</p>
                          
                        </div>
                        <div className="error_msg">{coupon_err}</div>
                      </>
                    }
                    </div>
                    <div className="error_msg">{coupon_err}</div>
                  </div>
                  <div className="summary-item">
                    <span>Pricing Details</span>
                    <div className="summary-primary-details">
                      <div className="price-details price-details-head">
                        <p>Subtotal</p>
                        <p>IGST (18%)</p>
                        <p>Shipping Charges</p>
                        <p>Discount</p>
                        <p>Total Price</p>
                        <p className="text-success">Total Savings</p>
                      </div>
                      <div className="price-details">
                        <p>₹{SummaryData.PricingDetail.CartTotalExclusiveTax}</p>
                        <p>₹{SummaryData.PricingDetail.GST}</p>
                        <p>₹{SummaryData.PricingDetail.ShippingCharges}</p>
                        <p>₹{SummaryData.PricingDetail.Discount || 0}</p>
                        <p>₹{SummaryData.PricingDetail.TotalPrice}</p>
                        <p className="text-success">₹{SummaryData.PricingDetail.TotalSavings}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="gradient_btn btn-order btn-block"
                  onClick={PlaceOrder}
                >
                  Place Order
                </button>
              </div>
            </aside>
        </div>  
        ) : isError ? <div className="error_msg">{err_msg}</div> : (
          <Loader />
        )}
    </div>
  );
}

export default PrintSummary;
