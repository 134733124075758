import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../utils/ApiRequest";


export const fetchUserData = createAsyncThunk("user/fetchUserData", async () => {
    try{
        const response = await instance.get(`/account/users/fetch-profile`, {
        });
        return response.data.Data;
    }catch(error){
        throw error;

    }
  });


const UserDataSlice = createSlice({
    name: 'user',
    initialState:{
        user: {},
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
      .addCase(fetchUserData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
    }
})

export default UserDataSlice.reducer;
