import { useCallback, useEffect, useRef } from 'react';

function StickyHeader ( props ) {
    const ref = useRef( null );

    const headerHight = document.getElementsByClassName("header-top");

    const scrollHandler = useCallback(() => {
        let stickyContent = ref.current.children[ 0 ];
        if ( window.pageYOffset > headerHight[0].offsetHeight ) {
            if ( !stickyContent.classList.contains( 'fixed' ) ) {
                stickyContent.classList.add( 'fixed' );
                document.body.classList.add('sticky_class');
            }
        } else if ( stickyContent.classList.contains( 'fixed' ) ) {
            stickyContent.classList.remove( 'fixed' );
            document.body.classList.remove('sticky_class');
        }
    },[headerHight])


    const resizeHandler  = useCallback(() => {
        scrollHandler();
    },[scrollHandler])

    useEffect( () => {
        // router.events.on( 'hashChangeComplete', initSticky );
        scrollHandler();
        window.addEventListener( 'scroll', scrollHandler, {
            passive: true
        } );

        window.addEventListener( 'resize', resizeHandler, {
            passive: true
        } );

        return () => {
            window.removeEventListener( 'scroll', scrollHandler );
            window.removeEventListener( 'resize', resizeHandler );
        }
    },[resizeHandler, scrollHandler])

    return (
        <div ref={ ref } className="sticky-wrapper">
            { props.children }
        </div>
    );
}

export default StickyHeader;