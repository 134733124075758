import React, { useState, useEffect, useRef } from 'react';

const CustomSelect = ({ defaultValue, onChange, Standard, Express }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const findCourierById = (id) => {
    const foundInStandard = Standard && Standard.find(courier => courier.CourierId === id);
    const foundInExpress = Express && Express.find(courier => courier.CourierId === id);

    return foundInStandard || foundInExpress || null;
  };

  const foundCourier = findCourierById(defaultValue);

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (value) => {
    setIsOpen(false);
    onChange(value);
  };

  return (
    <div className="custom-select" ref={dropdownRef}>
      <div className="select-header" onClick={toggleOptions}>
        <div className="shipping-opt">
          <p>{foundCourier.CourierName}</p>
          <p>Estimated Delivery in {foundCourier.EDD } days - ₹{foundCourier.Charges}</p>
        </div>
        <span className={`arrow ${isOpen ? 'open' : ''}`}>&#9662;</span>
      </div>
      {isOpen && (
        <div className="select-options">
          {Standard.length > 0 && (
            <div className="select-option">
              <div className="group-label">Standard</div>
              {Standard.map((ele, ind) => (
                <div
                  className="option"
                  key={ind}
                  onClick={() => selectOption(ele.CourierId)}
                >
                  <div className={`shipping-opt ${defaultValue === ele.CourierId ? "selected" : ""}`}>
                    <p>{ele.CourierName}</p>
                    <p>Estimated Delivery in {ele.EDD} days - ₹{ele.Charges}</p>
                  </div>
                  {defaultValue === ele.CourierId &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                    </svg>
                  }
                </div>
              ))}
            </div>
          )}
          {Express.length > 0 && (
            <div className="select-option">
              <div className="group-label">Express</div>
              {Express.map((ele, ind) => (
                <div
                  className="option"
                  key={ind}
                  onClick={() => selectOption(ele.CourierId)}
                >
                  <div className={`shipping-opt ${defaultValue === ele.CourierId ? "selected" : ""}`}>
                    <p>{ele.CourierName}</p>
                    <p>Estimated Delivery in {ele.EDD} days - ₹{ele.Charges}</p>
                  </div>
                  {defaultValue === ele.CourierId &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                    </svg>
                  }
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
