import React, { useEffect, useState } from 'react';
import './notification.css';
import { instance } from '../../utils/ApiRequest';

function Notification() {

    const [preferences, setPreferences] = useState(null);
    const [DisplayMapping, setDisplayMapping] = useState(null);
    const [error_msg, setError_msg] = useState('');

    const handleToggle = async(platform, key, value) => {
        const data = {
            "Medium": platform,
            "Notification": key,
            "Status": !value
        }
        await instance.put('account/notification-preferences/update', data)
        .then(res => {

        })
        .catch(err => {
            setError_msg(err.response.message)
        })
    };

    const FetchNotifications = async() => {
        await instance.get('account/notification-preferences/list')
        .then(res => {
            setPreferences(res.data.Data.Preferences);
            setDisplayMapping(res.data.Data.DisplayMapping);
        })
        .catch(err => {
            
        })
    }

    useEffect(() => {
        FetchNotifications();
    },[])
  return (
    <div className="preferences-container">
        <div className='error_msg'>{error_msg}</div>
        {preferences ? 
        Object.keys(preferences).map((platform, index) => (
            <div key={platform} className="platform-preferences">
                <h2>{platform}</h2>
                <div className="preference-panel">
                    {Object.entries(preferences[platform]).map(([key, value]) => (
                        <div key={key} className="preference-item">
                            <input
                                type="checkbox"
                                id={`${platform}-${key}`}
                                defaultChecked={value}
                                onChange={() => handleToggle(platform, key, value)}
                            />
                            <label htmlFor={`${platform}-${key}`}>{DisplayMapping[key]}</label>
                        </div>
                    ))}
                </div>
            </div>
        )) :
        <h3>No Notification preferences available yet.</h3>
        }
    </div>
  )
}

export default Notification