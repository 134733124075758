import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./shop.css";
import cart from './cart.png'
import ShippingAddress from "../Shipping/ShippingAddress";
import BillingAddress from "../Billing/BillingAddress";
import PaymentMethod from "../Payment/PaymentMethod";
import { instance } from "../../../utils/ApiRequest";
import SummaryDetails from "../Summary/SummaryDetails";

function Shop({ setOrderDetail, user }) {
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutDetails, setCheckoutDetails] = useState(null);
  const [noCheckout, setNoCheckout] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const Shipping = location.pathname === "/checkout/shop/shipping";
  const Billing = location.pathname === "/checkout/shop/billing";
  const Payment = location.pathname === "/checkout/shop/payment";
  const Summary = location.pathname === "/checkout/shop/summary";

  const FetchCheckout = async () => {
    setIsLoading(true);
    await instance
      .get("/account/checkout/shop/fetch")
      .then((res) => {
        setCheckoutDetails(res.data.Data);
        setIsLoading(false);
      })
      .catch((err) => {
        
        setIsLoading(false);
        setNoCheckout(true);
      });
  };

  useEffect(() => {
    FetchCheckout();
  }, [navigate]);

  return (
    <div className="shop_checkout">
      { 
      !isLoading ?
      checkoutDetails && !noCheckout ? (
        <>
          {Shipping && <ShippingAddress checkoutDetails={checkoutDetails} user={user} />}
          {Billing && <BillingAddress checkoutDetails={checkoutDetails} user={user}/>}
          {Payment && <PaymentMethod checkoutDetails={checkoutDetails} />}
          {Summary && <SummaryDetails setOrderDetail={setOrderDetail} />}
        </>
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3><strong>Checkout</strong></h3>
                </div>
                <div className="card-body cart">
                  <div className="col-sm-12 empty-cart-cls text-center">
                    <img
                      src={cart}
                      width="130"
                      height="130"
                      className="img-fluid mb-4 mr-3"
                      alt="cart-icon"
                    />
                    <h3>
                      <strong>Your Cart is Empty</strong>
                    </h3>
                    <h4>Add something to make me happy :)</h4>
                    <a
                      href={process.env.REACT_APP_SHOP_URL}
                      className="btn btn-outline-primary m-3"
                      data-abc="true"
                    >
                      back to shopping
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      ):
      <div>Loading</div>
      }
    </div>
  );
}

export default Shop;
