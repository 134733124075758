import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { pathNames } from '../../utils';
import { instance } from '../../utils/ApiRequest';
import Account from './Account';
import Address from './Address';
import Coupons from './Coupons';
import UpdateAddressComponent from './UpdateAddressComponent';
import Orders from './Orders/Orders';
import ViewOrder from './Orders/ViewOrder';
import Notification from './Notification';
import PageHeader from '../Features/PageHeader';


function DashBoard ({setIsLoggedIn}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState(null);

    const sideBar = pathNames.map(item => {
        if(location.pathname === item.path){
            return true;
        }
        return false;
    });

    const fetch_user_data = '/account/users/fetch-profile';

    // active components by pathname
    const dashboard = location.pathname === '/dashboard';
    const account = location.pathname === '/profile';
    const address = location.pathname === '/addresses';
    const orders = location.pathname === '/orders/shop' || location.pathname === '/orders/print';
    const view_order = location.pathname.includes('/shop/') || location.pathname.includes('/print/') ;
    const coupons = location.pathname === '/coupons';
    const notifications = location.pathname === '/notification-preferences';
    const address_edit = location.pathname.includes('/address/edit');
    const address_add = location.pathname === '/address/add';

    const fetchUserData = useCallback(() => {
        instance.get(fetch_user_data)
        .then(res => {
            setUser(res.data.Data)
        })
        .catch(err => {
            
        })
    },[])

    useEffect(() => {
        fetchUserData();
    },[fetchUserData])

    function handleLogout(){
        instance.post('/account/users/logout')
        .then(res => {
            if(res.data){
                localStorage.removeItem('user_logged_in');
                setIsLoggedIn(false);
                navigate('/login');
            }
        }).catch(err => {
        
        })
    }
    if(!user){
        return(
            <div></div>
        )
    }

    return (
        <div className="main">
            <div className="dashboard">
                {dashboard && <PageHeader title={"Dashboard"} subTitle="" />}
                { orders  && <PageHeader title={"Orders"} subTitle="" /> }
                { view_order && <PageHeader title={"Order Detail"} subTitle="" /> }
                { address  && <PageHeader title={"Addresses"} subTitle="" /> }
                { coupons  && <PageHeader title={"Coupons"} subTitle="" />}
                { account  && <PageHeader title={"Profile"} subTitle="" /> }
                {notifications && <PageHeader title={"Notification Preferences"} subTitle="" /> }
                <ul className="nav nav-dashboard container flex-column mb-3 mb-md-0" role="tablist">
                    <div className="row">
                        { sideBar.length > 0 && (
                            <>
                                <aside className="col-md-4 col-lg-3 mb-md-0 sticky-content">
                                    <ul>
                                        <li className={dashboard ? 'nav-item active' : 'nav-item'}><Link to='/dashboard' className="nav-link">DashBoard</Link></li>
                                        <li className={account ? 'nav-item active' : 'nav-item'}><Link to='/profile' className="nav-link">Profile</Link></li>
                                        <li className={(address || address_add || address_edit) ? 'nav-item active' : 'nav-item'}><Link to='/addresses' className="nav-link">Addresses</Link></li>
                                        {/* <li className={change_password ? 'nav-item active' : 'nav-item'}><Link to='/change-password' className="nav-link">Change Password</Link></li> */}
                                        <li className={(orders || view_order) ? 'nav-item active' : 'nav-item'}><Link className="nav-link" to='/orders/shop'>Orders</Link></li>
                                        <li className={coupons ? 'nav-item active' : 'nav-item'}><Link to='/coupons' className="nav-link">Coupons</Link></li>
                                        <li className={notifications ? 'nav-item active' : 'nav-item'}><Link to='/notification-preferences' className="nav-link">Notifications</Link></li>
                                        <li className='nav-item'><Link to="/login" className="nav-link" onClick={handleLogout}>Sign Out</Link></li>
                                    </ul>
                                </aside>
                                <div className="col-md-8 col-lg-9" style={ { marginTop: "1rem" } }>
                                    { dashboard && (
                                        <div className="tab-pane">
                                            <p>Hello <span className="user">{user.FirstName}</span> (not <span className="user">{user.FirstName}</span>? <Link href="/" onClick={handleLogout}>Log out</Link>)
                                            <br />
                                            From your account dashboard you can view your
                                            <Link to='/orders/shop' className="tab-trigger-link"> recent orders </Link>, manage your 
                                            <Link to='/address' className="tab-trigger-link"> shipping and billing addresses </Link>and 
                                            <Link to='/account' className="tab-trigger-link"> edit your account details </Link>.</p>
                                        </div>
                                    )}
                                    { orders  && <Orders /> }
                                    { view_order && <ViewOrder /> }
                                    { address  && <Address /> }
                                    { address_add  && <UpdateAddressComponent user={user} /> }
                                    { address_edit && <UpdateAddressComponent user={user} /> }
                                    { coupons  && <Coupons /> }
                                    { account  && <Account user={user} fetchUserData={fetchUserData}/> }
                                    {notifications && <Notification /> }
                                </div>
                            </>
                        )}
                    </div>
                </ul>
            </div>
        </div>
    )
}

export default DashBoard;