import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { instance, mergerCart } from '../../utils/ApiRequest';

function Signup({isLoggedIn}) {
    const [mobile, setMobile] = useState('');
    const [code, setCode] = useState(['', '', '', '']);
    const [isShow, setIsShow] = useState(false);
    const [validation, setValidation] = useState('');
    const [time, setTime] = useState(60); // Timer starts at 60 seconds
    const [isTimerActive, setIsTimerActive] = useState(false);
    const navigate = useNavigate();
    
    // Get the shop parameter from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const shopParam = searchParams.get('redirectTo');
    
    useEffect(() => {
        if(isLoggedIn){
            navigate('/dashboard');
        }
        let timer = null;
        if (isTimerActive && time > 0) {
            timer = setInterval(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000); // Decrease time every second
        } else if (time === 0) {
            setIsTimerActive(false); // Stop the timer when time is up
        }
        return () => clearInterval(timer); 
    }, [ navigate, isLoggedIn, isTimerActive, time])
    
    const onLogin = (e) => {
        e.preventDefault();
        const updatedOtp = code.join('');
        if(updatedOtp && code.length > 0){
            if(updatedOtp.length > 3){
                instance.post('/account/users/login-with-otp', {"OTP": updatedOtp})
                .then(res => {
                    mergerCart();
                    if(res.data.Data.IsNewUser === true){
                        navigate('/pending-profile', { state: {mobile: mobile} });
                    }else{
                        if(shopParam){
                            window.location = shopParam;
                        }else{
                            window.location = process.env.REACT_APP_HOME_URL;
                        }
                    }
                })
                .catch(err => {
                    setValidation(err.response.data.Message);
                })
            }else{
                setValidation("Please fill all fields!");
            }
        }else{
            if(mobile){
                instance.post('/account/users/get-otp', {"Mobile" : mobile})
                .then(res => {
                    setIsShow(true);
                    setTime(60);
                    setIsTimerActive(true);
                })
                .catch(err => {
                    if(err.response.data.Message === "OTP sent recently."){
                        setIsShow(true);
                    }else{
                        setValidation(err.response.data.Message);
                    }
                })
            }else{
                setValidation("Mobile number is required!");
            }
        }
        setTimeout(() => {
            setValidation('');
        }, 3000);
    }
   
    const handleMobileChange = (e) => {
        const input = e.target.value;
        const numericInput = input.replace(/^\+91/, '').replace(/\D/g, '');
        setMobile(numericInput);
    };

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (value.length <= 1 && /^[0-9]*$/.test(value)) {
          const newCode = [...code];
          newCode[index] = value;
          setCode(newCode);
    
          // Move focus to the next input field if a digit is entered
          if (value && index < code.length - 1) {
            document.getElementById(`input-${index + 1}`).focus();
          }
        }
    };

    const mobChange = () => {
      setIsShow(false);
    }
  return (
    <div className='login_signup'>
        <div className="modal-body">
            <div className="form-box">
                <div className='error_msg'>{validation}</div>
                <div className="text-center">
                    <h3 className="text">{isShow ? "Verify the OTP" : "Login / Signup"}</h3>
                    <hr />
                    {isShow ? <p className='text'>Enter the 4-digit OTP code that was sent to your mobile {mobile}.</p> : <p className='label'>Your Mobile Number</p>}
                </div>
                <div className="form-tab">
                    <div className={isShow ? "tab-content otp_content" : "tab-content"}>
                        <form onSubmit={onLogin}>
                        {isShow ?
                            <span className="mobile_text">
                                Change number? <span className="mobile_change" onClick={mobChange}>change</span>
                            </span>
                            :
                            <div className="form-group mobile_input">
                                <span>+91 </span>
                                <input type="text"
                                    className="form-control"
                                    id="mobilel" 
                                    name="mobilel" 
                                    maxLength={13}
                                    value={`   ${mobile}`} 
                                    onChange={handleMobileChange} 
                                />
                            </div>
                        }
                            
                        {isShow &&
                        <div className="form-group form_otp">
                            {code.map((num, index) => (
                               <input
                                  key={index}
                                  id={`input-${index}`}
                                  type="text"
                                  value={num}
                                  inputmode="numeric"
                                  onChange={(e) => handleChange(e, index)}
                                />
                            ))}
                        </div>
                        }
                            <div className="form-footer">
                                <button type="submit" id="login" className={`gradient_btn ${isShow && code.every(element => element === "") ? "disabled" : ""}`}>
                                    <span>{isShow ? "Verify Otp" : "Get Verification code"}</span>
                                </button>
                                {isShow ?
                                isTimerActive ?
                                <div className='forgot-link'>
                                    <span>Resend in 00:{time}s</span>
                                </div>
                                :
                                <div className='forgot-link'>
                                    <span>Didn't you get Code? </span>
                                    <Link href="#" className="resend-link" onClick={onLogin}>Resend</Link>
                                </div>
                                : ''
                                }
                                <div className="text-center problem_link">
                                    <p>Unable to login?</p><a href={process.env.REACT_APP_SUPPORT_URL} className="resend-link">Contact us</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Signup