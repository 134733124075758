import React, { useCallback, useEffect } from 'react'
import { instance } from '../../../utils/ApiRequest';
import { useLocation } from 'react-router-dom';

function RasorPay({OrderDetail, RazorpayOrderId}) {
    var orderDetail = OrderDetail && OrderDetail;
    const location = useLocation();

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    const displayRazorpay = useCallback(async() => {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        const options = {
            "key": process.env.REACT_APP_RZP_KEY, // Enter the Key ID generated from the Dashboard
            "amount": orderDetail.PaymentDetail.TotalPayment, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "KROILA 3D Private Limited",
            "description": "Test Transaction",
            "image": "https://cdn.ocean3d.in/global/logo.png",
            "order_id": RazorpayOrderId || orderDetail.PaymentDetail.Razorpay.RazorpayOrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": async function(response){
                const body  = {
                    "OrderID": orderDetail.OrderID,
                    "RazorpayOrderId": response.razorpay_order_id,
                    "RazorpayPaymentId": response.razorpay_payment_id,
                    "RazorpaySignature": response.razorpay_signature
                };
                if(location.pathname.includes('/shop')){
                    await instance.post('/account/orders/shop/confirm-razorpay-payment', body)
                    .then(res => {
                        window.location.reload();
                    })
                    .catch(err => {
                    })
                }else{
                    await instance.post('/account/orders/print/confirm-razorpay-payment', body)
                    .then(res => {
                        window.location.reload();
                    })
                    .catch(err => {
                    })
                }
            },
            "prefill": {
                "name": orderDetail.BillingAddress.FirstName,
                "email": orderDetail.BillingAddress.Email,
                "contact": orderDetail.BillingAddress.Mobile
            },
            "notes": {
                "orderId": orderDetail.OrderID
            },
            "theme": {
                "color": "#0051bb"
            }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    },[RazorpayOrderId, location.pathname,
        orderDetail
    ])
    useEffect(() => {
        if(orderDetail){
            displayRazorpay();
        }
    }, [displayRazorpay, orderDetail])

    return (
        <div></div>
    );
}

export default RasorPay;