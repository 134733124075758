

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
export const pathNames = [
  {path:'/dashboard'},
  {path:'/orders'},
  {path:'/profile'},
  {path:'/downloads'},
  {path:'/addresses'},
];

const states = [
  { value: "A&I", label: "Andaman and Nicobar Islands" },
  { value: "AP", label: "Andhra Pradesh" },
  { value: "AR", label: "Arunachal Pradesh" },
  { value: "AS", label: "Assam" },
  { value: "Bi", label: "Bihar" },
  { value: "CH", label: "Chandigarh" },
  { value: "CG", label: "Chhattisgarh" },
  { value: "D", label: "Delhi" },
  { value: "G", label: "Goa" },
  { value: "GJ", label: "Gujarat" },
  { value: "HR", label: "Haryana" },
  { value: "HP", label: "Himachal Pradesh" },
  { value: "JK", label: "Jammu and Kashmir" },
  { value: "JH", label: "Jharkhand" },
  { value: "KN", label: "Karnataka" },
  { value: "KL", label: "Kerala" },
  { value: "LD", label: "Lakshadeep" },
  { value: "MP", label: "Madhya Pradesh" },
  { value: "MH", label: "Maharashtra" },
  { value: "Manipur", label: "Manipur" },
  { value: "MG", label: "Meghalaya" },
  { value: "MZ", label: "Mizoram" },
  { value: "NL", label: "Nagaland" },
  { value: "OD", label: "Orissa" },
  { value: "PCH", label: "Pondicherry" },
  { value: "PJ", label: "Punjab" },
  { value: "RJ", label: "Rajasthan" },
  { value: "SK", label: "Sikkim" },
  { value: "TN", label: "Tamil Nadu" },
  { value: "TG", label: "Telangana" },
  { value: "Tripura", label: "Tripura" },
  { value: "UT", label: "Uttaranchal" },
  { value: "UP", label: "Uttar Pradesh" },
  { value: "WB", label: "West Bengal" }
];

export function getStates(){
  return states;
}

export function getState(item){
  const state = states.find(ele => ele.value === item);
  return state;
}

export function formatDate(dateString){
  const date = new Date(dateString);

  const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
  ];

  
  // Get the day, month, and year
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  
  function getOrdinalSuffix(day) {
    if (day >= 11 && day <= 13) return 'th';
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    }
  
  const suffix = getOrdinalSuffix(day);
  // Format the date as "31 July 2024"
  const formattedDate = `Valid till ${day}${suffix} ${month} ${year}`;
  return formattedDate;
}