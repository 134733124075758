import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function PageHeader ( props ) {
    const { title, subTitle } = props;

    const location = useLocation();
    const pathnames = location.pathname.split('/').filter(x => x);
  

    return (
        <div className="page-header text-center" style={ { background: '#000' } } >
            <div className="container">
                <h1 className="page-title">{ title }<span>{ subTitle }</span></h1>
                <ul>
                    <li><Link to='/' >Account</Link></li>
                    {pathnames.map((value, index) => {
                        const pathname = `/${pathnames.slice(0, index + 1).join('/')}`;
                        return (
                            <React.Fragment key={pathname}>
                            <li>/</li>
                            <li>
                                {index === pathnames.length - 1 ? (
                                // Last breadcrumb item (current page) is not a link
                                value.charAt(0).toUpperCase() + value.slice(1)
                                ) : (
                                <Link to={pathname}>
                                    {value.charAt(0).toUpperCase() + value.slice(1)}
                                </Link>
                                )}
                            </li>
                            {index < pathnames.length - 1 && <span> / </span>}
                            </React.Fragment>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

export default PageHeader;