import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddressList } from '../../../store/Address';
import { useLocation, useNavigate } from 'react-router-dom';
import { instance } from '../../../utils/ApiRequest';
import Loader from '../../Loader';
import NewCheckout from '../Shop/NewCheckout';

function ShippingAddress({ checkoutDetails, user }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [setSameAsShipping, setSetSameAsShipping] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentOptions, setPaymentOptions] = useState(null);
    const [selectedOption, setSelectedOption] = useState();
    const [addnewAddress, setaddnewAddress] = useState(null);
    const [validationError, setValidationError] = useState('');
    const [error, setError] = useState({});
    const [billingErrors, setBillingErrors] = useState({});
    const [formData, setFormData] = useState({
        FirstName: (user && user.FirstName) || '',
        LastName: (user && user.LastName) || '',
        Address1: '',
        Email: (user && user.Email) || '',
        Mobile: (user && user.Mobile) || '',
        Address2: '',
        City: '',
        State: '',
        Pincode: '',
        CompanyName: ''
    });

    const [billingFormData, setBillingFormData] = useState({
        FirstName: (user && user.FirstName) || '',
        LastName: (user && user.LastName) || '',
        Address1: '',
        Email: (user && user.Email) || '',
        Mobile: (user && user.Mobile) || '',
        Address2: '',
        City: '',
        State: '',
        Pincode: '',
        CompanyName: '',
        GSTIN: ''
    });

    const AllAddress = useSelector(state => state.AddressList.address);
    const selectedPayment = checkoutDetails.PaymentDetail && checkoutDetails.PaymentDetail.PaymentMethod;
    const { state } = useLocation();

    const FetchPaymentOptions = useCallback(async () => {
        await instance
            .get("/account/checkout/shop/payment-methods")
            .then((res) => {
                if (res.data.Status === "Success") {
                    setPaymentOptions(res.data.Data);
                    setTimeout(() => {
                        setSelectedOption(selectedPayment);
                    }, 500);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }, [selectedPayment]);

    function selectOption(value) {
        setSelectedOption(value);
    };

    function SelectAddress(item) {
        setaddnewAddress(item._id);
        setFormData({
            FirstName: item.FirstName || item.FullName,
            LastName: item.LastName || item.FullName,
            Address1: item.Address1,
            Email: item.Email,
            Mobile: "" + item.Mobile,
            Address2: item.Address2,
            City: item.City,
            State: item.State,
            Pincode: "" + item.Pincode,
            CompanyName: item.CompanyName || ''
        });
        setBillingFormData({
            FirstName: item.FirstName || item.FullName,
            LastName: item.LastName,
            Address1: item.Address1,
            Email: item.Email,
            Mobile: "" + item.Mobile,
            Address2: item.Address2,
            City: item.City,
            State: item.State,
            Pincode: "" + item.Pincode,
            CompanyName: item.CompanyName || '',
            GSTIN: item.GSTIN || ''
        });
        // setChangeAddress(true);
    }

    const handleSameAsShipping = () => {
        setSetSameAsShipping(prev => !prev);
        setBillingFormData({
            FirstName: formData.FirstName,
            LastName: formData.LastName,
            Address1: formData.Address1,
            Email: formData.Email,
            Mobile: "" + formData.Mobile,
            Address2: formData.Address2,
            City: formData.City,
            State: formData.State,
            Pincode: "" + formData.Pincode,
            CompanyName: formData.CompanyName || '',
            GSTIN: formData.GSTIN || ''
        });
    };

    useEffect(() => {
        if(state){
            const mobile_field = state.mobile_change;
            if(mobile_field === 'true'){
                const mobileInput = document.querySelector(`input[name="Mobile"]`);
                if (mobileInput) {
                    const offset = 100; // Adjust this value as needed for spacing
                    const rect = mobileInput.getBoundingClientRect();
                    const scrollToPosition = window.scrollY + rect.top - offset;
    
                    window.scrollTo({
                        top: scrollToPosition,
                        behavior: 'smooth'
                    });
                    mobileInput.focus();
                }
            }
        }
        if (checkoutDetails.ShippingAddress) {
            setFormData({
                FirstName: checkoutDetails.ShippingAddress.FullName || checkoutDetails.ShippingAddress.FirstName || '',
                LastName: checkoutDetails.ShippingAddress.LastName,
                Address1: checkoutDetails.ShippingAddress.Address1 || '',
                Email: checkoutDetails.ShippingAddress.Email || '',
                Mobile: checkoutDetails.ShippingAddress.Mobile + '' || '',
                Address2: checkoutDetails.ShippingAddress.Address2 || '',
                City: checkoutDetails.ShippingAddress.City || '',
                State: checkoutDetails.ShippingAddress.State || '',
                Pincode: checkoutDetails.ShippingAddress.Pincode || '',
                CompanyName: checkoutDetails.ShippingAddress.CompanyName || ''
            });
            setIsLoading(false);
        }
        if (checkoutDetails.UserId) {
            dispatch(fetchAddressList());
        }
        FetchPaymentOptions();
    }, [checkoutDetails, dispatch, navigate, FetchPaymentOptions, state]);


    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const numberRegex = /^[0-9]*$/;
    const gstin = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/;
    // Validation function
    const validateFields = (data) => {
        const errors = {};

        // Loop through form data and validate fields
        for (const key in data) {
            if (!data[key] && key !== 'CompanyName' && key !== 'Address2' && key !== 'GSTIN') {
                errors[key] = 'This field is required';
            }
            if (key === 'Email' && data[key] && !emailRegex.test(data[key])) {
                errors['Email'] = 'Please enter a valid email';
            }
            if (key === 'Mobile' && data[key].length !== 10) {
                errors['Mobile'] = 'Please enter a valid mobile';
            }
            if(key === 'GSTIN' && data[key].length !== 0 && !gstin.test(data[key])){
                errors['GSTIN'] = 'Please enter a valid GST number';
            }
        }
        
        return errors;
    };

    const handleChangeFields = (e) => {
        const { name, value } = e.target;

        // Allow only numbers for Mobile and Pincode fields
        if ((name === 'Mobile' || name === 'Pincode') && !numberRegex.test(value)) {
            return;
        }

        setFormData(prev => {
            const newFormData = { ...prev, [name]: value };
            const currentErrors = validateFields({ [name]: value });
            setError(prevErrors => ({
                ...prevErrors,
                ...currentErrors,
                // Clear the error for the current field if it passes validation
                [name]: currentErrors[name] ? currentErrors[name] : undefined,
            }));

            return newFormData;
        });
    };

    const handleBillingBlur = (e) => {
        const { name, value } = e.target;
    
        // Validate the current field on blur
        const currentErrors = validateFields({ [name]: value });
    
        // Update error state
        setBillingErrors(prevErrors => ({
            ...prevErrors,
            ...currentErrors,
        }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
    
        // Validate the current field on blur
        const currentErrors = validateFields({ [name]: value });
    
        // Update error state
        setError(prevErrors => ({
            ...prevErrors,
            ...currentErrors,
        }));
    };

    const handleChangeBillingFields = (e) => {
        const { name, value } = e.target;
        // Allow only numbers for Mobile and Pincode fields
        if ((name === 'Mobile' || name === 'Pincode') && !numberRegex.test(value)) {
            return;
        }

        setBillingFormData(prev => {
            const newFormData = { ...prev, [name]: value };
            const currentErrors = validateFields({ [name]: value });
            setBillingErrors(prevErrors => ({
                ...prevErrors,
                ...currentErrors,
                // Clear the error for the current field if it passes validation
                [name]: currentErrors[name] ? currentErrors[name] : undefined,
            }));

            return newFormData;
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        const shippingErrors = validateFields(formData);
        var billingErrors = {};
        if(setSameAsShipping && !billingFormData.GSTIN){
            billingErrors = {};
        }else if(setSameAsShipping && billingFormData.GSTIN){
            billingErrors = {GSTIN: validateFields(billingFormData).GSTIN};
        }else{
            billingErrors = validateFields(billingFormData);
        }
        
        if (Object.keys(shippingErrors).length || Object.keys(billingErrors).length) {
            setError(shippingErrors);
            setBillingErrors(billingErrors);
            // Find the first error field
            const firstErrorFieldName = Object.keys(shippingErrors).length
            ? Object.keys(shippingErrors)[0]
            : Object.keys(billingErrors)[0];

            const parentClass = Object.keys(shippingErrors).length < 1 ? '.billing' : '';

            // Find the first error field (input or select)
            const firstErrorField = document.querySelector(`${parentClass} input[name="${firstErrorFieldName}"], ${parentClass} select[name="${firstErrorFieldName}"]`);
            
            if (firstErrorField) {
                const offset = 100; // Adjust this value as needed for spacing
                const rect = firstErrorField.getBoundingClientRect();
                const scrollToPosition = window.scrollY + rect.top - offset;

                window.scrollTo({
                    top: scrollToPosition,
                    behavior: 'smooth'
                });
                firstErrorField.focus();
            }
            if(!selectedPayment && !selectedOption){
                setValidationError('Please select payment method!');
                setTimeout(() => {
                    setValidationError('');
                }, 3000);
            }
            return;
        }

        const ShippingBilling = {
            FirstName: formData.FirstName,
            LastName: formData.LastName,
            Address1: formData.Address1,
            Email: formData.Email,
            Mobile: "" + formData.Mobile,
            Address2: formData.Address2,
            City: formData.City,
            State: formData.State,
            Pincode: "" + formData.Pincode,
            CompanyName: formData.CompanyName || '',
            GSTIN: billingFormData.GSTIN || ''
        }

        const data = {
            Shipping: formData,
            Billing: setSameAsShipping ? ShippingBilling : billingFormData,
            PaymentMethod: {
                "PaymentMethodId": selectedOption
            }
        };

        try {
            const res = await instance.put('/account/checkout/shop/update', data);
            if (res.data.Status === "Success") {
                navigate('/checkout/shop/summary', { state: { redirection: "false" } });
            }
        } catch (err) {
            setValidationError(err.response.data.Message);
            // console.error(err);
        }
    };

    return (
        <div className='col-md-12 form-box shipping_address mt-3'>
            {!isLoading ? (
                <div className='address_form'>
                    <NewCheckout
                        onSubmit={onSubmit}
                        setSameAsShipping={setSameAsShipping}
                        error={error}
                        billingErrors={billingErrors}
                        handleSameAsShiiping={handleSameAsShipping}
                        shipping={checkoutDetails.ShippingAddress}
                        billingFormData={billingFormData}
                        AddressList={AllAddress.Data}
                        updateFields={formData}
                        handleChangeFields={handleChangeFields}
                        handleChangeBillingFields={handleChangeBillingFields}
                        User={user}
                        handleBillingBlur={handleBillingBlur}
                        handleBlur={handleBlur}
                        SelectAddress={SelectAddress}
                        addnewAddress={addnewAddress}
                        setFormData={setFormData}
                    />
                </div>
            ) : (
                <Loader />
            )}
            <div className="shop_checkout payment-options">
                <div className="payment-type">
                    <h3>Payment method</h3>
                    <div className="types flex justify-space-between">
                    {!paymentOptions && checkoutDetails ? (
                        <div>Loading</div>
                    ) : (
                        paymentOptions &&
                        paymentOptions.map((option, index) => {
                        return (
                            <div className={(selectedOption === option._id) ? "type selected" : "type"} key={index} onClick={() => selectOption(option._id)}>
                            <div className="icon">
                                <img src={option.Image} alt={option.Name}/>
                            </div>
                            <div className="text">
                                <strong>{option.Name}</strong>
                                <p>{option.Description}</p>
                            </div>
                            </div>
                        );
                        })
                    )}
                    </div>
                    <span className='error_msg'>{validationError}</span>
                </div>
                <button className="gradient_btn float-end" onClick={onSubmit}>
                    Proceed Checkout
                </button>
            </div>
        </div>
    );
}

export default ShippingAddress;
