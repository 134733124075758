import React, { useState } from 'react'
import { instance } from '../../utils/ApiRequest';

function Account({user, fetchUserData}) {
    const [updateFields, setUpdateFields] = useState({ FirstName:user.FirstName, LastName: user.LastName, Email:user.Email, Mobile:user.Mobile});
    const [error, setError] = useState({FirstName:'', LastName: '', Email:'', Mobile:''});
    const [error_msg, setError_msg] = useState("");
    // Function to Register fields change
    const handleChangeFields = (e) => {
        const { name, value } = e.target;
        setUpdateFields({
        ...updateFields,
        [name]: value 
        });
    };

    function UpdateUser(data) {
      instance.put('/account/users/update-profile', data)
      .then(res => {
          setTimeout(() => {
              fetchUserData();
          }, 300);
      })
      .catch(err => {
        setError_msg(err.data.Data.Message);
        
      })
  }
    const onSubmit = (e) => {
        e.preventDefault();
        const email_regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let hasError = false;
        for (const key in updateFields) {
          if (!updateFields[key]) {
            setError(prevError => ({
                ...prevError,
                [key]: 'This field is required'
              }));
            hasError = true;
            if(key === 'Email' && !updateFields[key].match(email_regex)){
              setError(prevError => ({
                  ...prevError,
                  'Email': 'Please enter a valid email'
                }));
              hasError = true;
            }
          }
        }
        setTimeout(() => {
            setError({});
          }, 3000);
        if(!hasError){
            UpdateUser(updateFields);
        }
    }
  return (
    <div className='tab-pane form-box'>
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col-sm-6">
                  <label>First Name</label>
                  <input type="text" name="FirstName" className="form-control" value={updateFields.FirstName}  onChange={handleChangeFields}/>
                  {error.FirstName && <span className='text-danger'>{error.FirstName}</span>}
                </div>
                <div className="col-sm-6">
                  <label>Last Name</label>
                  <input type="text" name="LastName" className="form-control" value={updateFields.LastName} onChange={handleChangeFields}/>
                  {error.LastName && <span className='text-danger'>{error.LastName}</span>}
                </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <label>Email address</label>
                <input type="email" name='Email' className="form-control" value={updateFields.Email} onChange={handleChangeFields}/>
                {error.Email && <span className='text-danger'>{error.Email}</span>}
              </div>
              <div className='col-md-6'>
                <label>Phone *</label>
                <input type="tel" name='Mobile' className="form-control" value={updateFields.Mobile} onChange={handleChangeFields} disabled/>
                {error.Mobile && <span className='text-danger'>{error.Mobile}</span>}
              </div>
            </div>
            {error_msg && <span className='text-danger'>{error_msg}</span>}
            <button type="submit" className="gradient_btn">
                SAVE CHANGES
            </button>
        </form>
    </div>
  )
}

export default Account