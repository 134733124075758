import React, { useEffect, useState } from 'react'
import { instance } from '../../../utils/ApiRequest';
import OrderDetail from './OrderDetail';

function RequestOrderDetails() {
    const [OrderId, setOrderId] = useState('');
    const [mobile, setMobile] = useState('');
    const [validation, setValidation] = useState('');
    const [OrderData, setOrderData] = useState(null);
    const [otp, setOtp] = useState('');

    var guest_order = sessionStorage.getItem('guest_order_data');
    guest_order = JSON.parse(guest_order);

    useEffect(() => {
        setTimeout(() => {
            setValidation('');
        }, 5000);
    }, [validation])

    async function SendOtp(){
        const body = {
            "OrderID": OrderId,
            "Mobile": mobile
        }
        await instance.post('/account/orders/shop/send-guest-otp', body)
        .then(res => {
            if(res.data.Data){
                var responseOtp = res.data.Data.Message.split('.');
                setTimeout(() => {
                    setOtp(responseOtp[1]);
                }, 500);
            }
        })
        .catch(err => {
            setValidation(err.response.data.Message);
        })
    }

    async function GetOrderDetails(){
        const data = {
            "OrderID": OrderId,
            "OTP": otp
        }
        await instance.post('/account/orders/shop/fetch-guest-order-details', data)
        .then(res => {
            setOrderData(res.data.Data);
            sessionStorage.setItem('guest_order_data', JSON.stringify(res.data.Data));
            setValidation('');
        })
        .catch(err => {
            setValidation(err.response.data.Message);
        })
    }

    async function OnRequest(e){
        e.preventDefault();
        if(OrderId && mobile && otp === ''){
            SendOtp();
        }else{
            GetOrderDetails();
        }
    }
  return (
    <div className='request_order_details'>
        { OrderData === null && guest_order === null ?
            <div className='form-box'>
                <div className='error_msg'>{validation}</div>
                <form onSubmit={OnRequest}>
                    <div className="form-group">
                        <label htmlFor="order_id">Order ID *</label>
                        <input type="text"
                        className="form-control"
                        id="order_id" 
                        name="order_id" 
                        value={OrderId} 
                        onChange={(e) => setOrderId(e.target.value)} 
                        required />
                    </div>
                    {
                        otp === '' ? (
                        <div className="form-group">
                            <label htmlFor="mobile">Mobile *</label>
                            <div className='password_icon'>
                                <input type="text"
                                className="form-control" 
                                id="mobile" 
                                name="mobile" 
                                autoComplete='password' 
                                value={mobile} 
                                onChange={(e) => setMobile(e.target.value)} 
                                required />
                            </div>
                        </div>
                        ): 
                        (
                        <div className="form-group">
                            <label htmlFor="mobile">OTP *</label>
                            <div className='password_icon'>
                                <input type="text"
                                className="form-control" 
                                id="mobile" 
                                name="mobile" 
                                autoComplete='password' 
                                value={otp} 
                                onChange={(e) => setOtp(e.target.value)} 
                                required />
                            </div>
                        </div>
                        )
                    }
                    <div className="form-footer">
                        <button type="submit" className="btn btn-outline-primary">
                            <span>{otp === '' ? "Send OTP" : "Proceed"}</span>
                            <i className="icon-long-arrow-right"></i>
                        </button>
                    </div>
                </form>
            </div>
        :
        <OrderDetail OrderData={OrderData || guest_order} />
        }
    </div>
  )
}

export default RequestOrderDetails