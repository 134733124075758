import React, { useState } from "react";
import { getState, getStates } from "../../../utils";

function NewCheckout({
  updateFields,
  error,
  handleChangeFields,
  handleChangeBillingFields,
  setSameAsShipping,
  handleSameAsShiiping,
  billingFormData,
  billingErrors,
  AddressList,
  User,
  SelectAddress,
  addnewAddress,
  setFormData,
  handleBlur,
  handleBillingBlur
}) {
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const states = getStates();

  const handleAddressSelect = (item) => {
    SelectAddress(item);
    setIsPopupOpen(false);
    setIsAddingAddress(false);
  };

  const handleAddAddressClick = () => {
    setIsAddingAddress(true);
    setFormData({
      FirstName: (User && User.FirstName) || '',
      LastName: (User && User.LastName) || '',
      Address1: '',
      Email: (User && User.Email) || '',
      Mobile: (User && User.Mobile) || '',
      Address2: '',
      City: '',
      State: '',
      Pincode: '',
      CompanyName: ''
  })
  };
  
  const selectedAddress = AddressList?.find((item) => {
    if (addnewAddress !== null && addnewAddress === item._id) {
      return true;
    }
    return false;
  }) || AddressList?.find((item) => item.Default === true);
  
  return (
    <form>
      <div className="more_actions head">
        <h3>Shipping Address</h3>
        {isAddingAddress && (
          <span onClick={() => setIsPopupOpen(true)}>Select saved Address</span>
        )}
      </div>
      {isPopupOpen && (
        <div className="popup-overlay address_cards">
          <div className="popup-content">
            <div className="d-flex">
              <h2>Select an Address</h2>
              <span className="close_btn" onClick={() => setIsPopupOpen(false)}>X</span>
            </div>
            {AddressList.map((item, index) => (
              <div
                className="col-lg-12"
                key={index}
                onClick={() => handleAddressSelect(item)}
              >
                <div className={`card card-dashboard ${addnewAddress === item._id ? 'selected' : ''}`}>
                  <div className="card-body">
                    <div className="default-value">
                      <h3 className="card-title">
                        {item.FirstName + " " + item.LastName || item.FullName}
                      </h3>
                    </div>
                    <div className="address_body">
                      {item.CompanyName && (
                        <>
                          <span>{item.CompanyName}</span> <br />
                        </>
                      )}
                      {item.Address1}
                      <br />
                      {item.Address2 && (
                        <>
                          <span>{item.Address2}</span> <br />
                        </>
                      )}
                      {item.City}, {getState(item.State)?.label || item.State}{" "}
                      {item.Pincode} <br />
                      <span className="strong">Mobile: </span>
                      {item.Mobile}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <button onClick={() => setIsPopupOpen(false)}>Close</button>
          </div>
        </div>
      )}
      {User && !isAddingAddress && selectedAddress ? (
        <>
          <div className="row address_form">
            {selectedAddress && (
              <div className="col-lg-12">
                <div className="card card-dashboard">
                  <div className="card-body">
                    <div className="default-value">
                      <h3 className="card-title">
                        {selectedAddress.FirstName +
                          " " +
                          selectedAddress.LastName || selectedAddress.FullName}
                      </h3>
                    </div>
                    <div className="address_body">
                      {selectedAddress.CompanyName && (
                        <>
                          <span>{selectedAddress.CompanyName}</span> <br />
                        </>
                      )}
                      {selectedAddress.Address1}
                      <br />
                      {selectedAddress.Address2 && (
                        <>
                          <span>{selectedAddress.Address2}</span> <br />
                        </>
                      )}
                      {selectedAddress.City},{" "}
                      {getState(selectedAddress.State)?.label ||
                        selectedAddress.State}{" "}
                      {selectedAddress.Pincode} <br />
                      <span className="strong">Mobile: </span>
                      {selectedAddress.Mobile}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="more_actions">
            <span onClick={() => setIsPopupOpen(true)}>Change Address</span>
            <span className="" onClick={handleAddAddressClick}>
              Add new Address
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-sm-6">
              <label>First Name *</label>
              <input
                type="text"
                name="FirstName"
                className={`form-control ${
                  error.FirstName ? "border-danger" : ""
                }`}
                placeholder="Enter first name"
                value={updateFields.FirstName}
                onChange={handleChangeFields}
                onBlur={handleBlur}
              />
            </div>
            <div className="col-sm-6">
              <label>Last Name *</label>
              <input
                type="text"
                name="LastName"
                className={`form-control ${
                  error.LastName ? "border-danger" : ""
                }`}
                placeholder="Enter last name"
                value={updateFields.LastName}
                onChange={handleChangeFields}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <label>Company Name (optional)</label>
              <input
                type="text"
                name="CompanyName"
                placeholder="Your company name.."
                className="form-control"
                value={updateFields.CompanyName}
                onChange={handleChangeFields}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <label>Email address *</label>
              <input
                type="email"
                name="Email"
                className={`form-control ${error.Email ? "border-danger" : ""}`}
                placeholder="Enter your email"
                value={updateFields.Email}
                onChange={handleChangeFields}
                onBlur={handleBlur}
              />
            </div>
            <div className="col-sm-6">
              <label>Mobile *</label>
              <input
                type="text"
                name="Mobile"
                maxLength="10"
                inputMode="numeric"
                className={`form-control ${
                  error.Mobile ? "border-danger" : ""
                }`}
                placeholder="Enter your mobile number"
                value={updateFields.Mobile}
                onChange={handleChangeFields}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <label>Address *</label>
              <input
                type="text"
                name="Address1"
                className={`form-control ${
                  error.Address1 ? "border-danger" : ""
                }`}
                placeholder="Plot no:04/345...."
                value={updateFields.Address1}
                onChange={handleChangeFields}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <label>Landmark (optional)</label>
              <input
                type="text"
                name="Address2"
                className="form-control"
                placeholder="landmark..."
                value={updateFields.Address2}
                onChange={handleChangeFields}
              />
            </div>
            <div className="col-sm-6 select-custom">
              <label>State *</label>
              <select
                className={`form-control form-select-sm ${
                  error.State ? "border-danger" : ""
                }`}
                name="State"
                id="inputState"
                value={updateFields.State}
                onChange={handleChangeFields}
                onBlur={handleBlur}
              >
                <option value="">Select State</option>
                {states.map((state, index) => (
                  <option value={state.value} key={index}>
                    {state.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <label>City *</label>
              <input
                type="text"
                name="City"
                placeholder="City..."
                className={`form-control ${error.City ? "border-danger" : ""}`}
                value={updateFields.City}
                onChange={handleChangeFields}
                onBlur={handleBlur}
              />
            </div>
            <div className="col-sm-6">
              <label>Pincode *</label>
              <input
                type="number"
                name="Pincode"
                placeholder="Pincode..."
                maxLength="6"
                inputMode="numeric"
                className={`form-control ${
                  error.Pincode ? "border-danger" : ""
                }`}
                value={updateFields.Pincode}
                onChange={handleChangeFields}
                onBlur={handleBlur}
              />
            </div>
          </div>
        </>
      )}

      <div className="row same_shipping">
        <h3>Billing Address</h3>
        <label className="billing_shiping">
          <input
            type="checkbox"
            className="main_uni_courses"
            name="same_as_shipping"
            id="same_as_shipping"
            defaultChecked={setSameAsShipping}
            onChange={handleSameAsShiiping}
          />
          <span htmlFor="same_as_shipping" style={{ marginTop: "-20px" }}>
            Set same as Shipping Details
          </span>
        </label>
      </div>

      {setSameAsShipping === false && (
        <>
          <div className="col-md-12 billing">
            <div className="row">
              <div className="col-sm-6">
                <label>First Name *</label>
                <input
                  type="text"
                  name="FirstName"
                  className={`form-control ${
                    billingErrors.FirstName ? "border-danger" : ""
                  }`}
                  placeholder="Enter first name"
                  value={billingFormData.FirstName}
                  onChange={handleChangeBillingFields}
                  onBlur={handleBillingBlur}
                />
              </div>
              <div className="col-sm-6">
                <label>Last Name *</label>
                <input
                  type="text"
                  name="LastName"
                  placeholder="Enter last name"
                  className={`form-control ${
                    billingErrors.LastName ? "border-danger" : ""
                  }`}
                  value={billingFormData.LastName}
                  onChange={handleChangeBillingFields}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <label>Company Name (optional)</label>
                <input
                  type="text"
                  placeholder="Your company name"
                  name="CompanyName"
                  className="form-control"
                  value={billingFormData.CompanyName}
                  onChange={handleChangeBillingFields}
                  onBlur={handleBillingBlur}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <label>Email address *</label>
                <input
                  type="email"
                  name="Email"
                  placeholder="Enter your email"
                  className={`form-control ${
                    billingErrors.Email ? "border-danger" : ""
                  }`}
                  value={billingFormData.Email}
                  onChange={handleChangeBillingFields}
                />
              </div>
              <div className="col-sm-6">
                <label>Mobile *</label>
                <input
                  type="tel"
                  name="Mobile"
                  inputMode="numeric"
                  maxLength="10"
                  placeholder="Enter your mobile number"
                  className={`form-control ${
                    billingErrors.Mobile ? "border-danger" : ""
                  }`}
                  value={billingFormData.Mobile}
                  onChange={handleChangeBillingFields}
                  onBlur={handleBillingBlur}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <label>Address *</label>
                <input
                  type="text"
                  name="Address1"
                  placeholder="Plot no: 04/345..."
                  className={`form-control ${
                    billingErrors.Address1 ? "border-danger" : ""
                  }`}
                  value={billingFormData.Address1}
                  onChange={handleChangeBillingFields}
                  onBlur={handleBillingBlur}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <label>Landmark (optional)</label>
                <input
                  type="text"
                  name="Address2"
                  placeholder="Landmark..."
                  className="form-control"
                  value={billingFormData.Address2}
                  onChange={handleChangeBillingFields}
                  onBlur={handleBillingBlur}
                />
              </div>
              <div className="col-sm-6 select-custom">
                <label>State *</label>
                <select
                  className={`form-control form-select-sm ${
                    billingErrors.State ? "border-danger" : ""
                  }`}
                  name="State"
                  id="inputState"
                  value={billingFormData.State}
                  onChange={handleChangeBillingFields}
                  onBlur={handleBillingBlur}
                >
                  <option value="">Select State</option>
                  {states.map((state, index) => (
                    <option value={state.value} key={index}>
                      {state.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <label>City *</label>
                <input
                  type="text"
                  name="City"
                  placeholder="City..."
                  className={`form-control ${
                    billingErrors.City ? "border-danger" : ""
                  }`}
                  value={billingFormData.City}
                  onChange={handleChangeBillingFields}
                  onBlur={handleBillingBlur}
                />
              </div>
              <div className="col-sm-6">
                <label>Pincode *</label>
                <input
                  type="tel"
                  inputMode="numeric"
                  name="Pincode"
                  placeholder="Pincode..."
                  className={`form-control ${
                    billingErrors.Pincode ? "border-danger" : ""
                  }`}
                  value={billingFormData.Pincode}
                  onChange={handleChangeBillingFields}
                  onBlur={handleBillingBlur}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className="row">
        <div className="col-sm-12">
          <label>GSTIN (optional)</label>
          <input
            type="tel"
            name="GSTIN"
            placeholder="Enter your GSTIN"
            className={`form-control ${
              billingErrors.GSTIN ? "border-danger" : ""
            }`}
            value={billingFormData.GSTIN}
            onChange={handleChangeBillingFields}
          />
        </div>
      </div>
    </form>
  );
}

export default NewCheckout;
