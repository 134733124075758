import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function HomeRoute({isMaintenance}) {
    const navigate = useNavigate();
    useEffect(() => {
        if(isMaintenance == null){
            navigate('/maintenance');
        }else{
            navigate('/');
        }
    },[isMaintenance, navigate])
  return (
    <div>
        
    </div>
  )
}

export default HomeRoute