import React, {useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { instance } from '../../utils/ApiRequest';

function PendingProfile() {
    const {state} = useLocation();
    const [updateFields, setUpdateFields] = useState({ FirstName:"", LastName: "", Email:"", Mobile: state.mobile});
    const [error, setError] = useState({FirstName:'', LastName: '', Email:''});
    const [validation, setValidation] = useState('');

    // Get the shop parameter from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const shopParam = searchParams.get('redirectTo');
    
    // Function to Register fields change
    const handleChangeFields = (e) => {
        const { name, value } = e.target;
        setUpdateFields({
        ...updateFields,
        [name]: value 
        });
    };
    
    function UpdateUser(data) {
        instance.put('/account/users/update-profile', data)
        .then(res => {
            setTimeout(() => {
                localStorage.setItem('user_logged_in', true);
                if(shopParam){
                    window.location = shopParam;
                }else{
                    window.location = process.env.REACT_APP_HOME_URL;
                }
            }, 300);
        })
        .catch(err => {
          setValidation(err.data.Data.Message);
          
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const email_regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let hasError = false;
        for (const key in updateFields) {
          if (!updateFields[key]) {
            setError(prevError => ({
                ...prevError,
                [key]: 'This field is required'
              }));
            hasError = true;
            if(key === 'Email' && !updateFields[key].match(email_regex)){
              setError(prevError => ({
                  ...prevError,
                  'Email': 'Please enter a valid email'
                }));
              hasError = true;
            }
          }
        }
        setTimeout(() => {
            setError({});
          }, 3000);
        if(!hasError){
            UpdateUser(updateFields);
        }
    }
  return (
    <div className="form-box">
        <div className="tab-content">
            <div className="text-center"><h3>Profile</h3></div>
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <label>First Name *</label>
                <input type="text" name="FirstName" className="form-control" value={updateFields.FirstName}  onChange={handleChangeFields}/>
                {error.FirstName && <span className='text-danger'>{error.FirstName}</span>}
            </div>
            <div className="form-group">
                <label>Last Name *</label>
                <input type="text" name="LastName" className="form-control" value={updateFields.LastName} onChange={handleChangeFields}/>
                {error.LastName && <span className='text-danger'>{error.LastName}</span>}
            </div>
            <div className="form-group">
                <label>Email address *</label>
                <input type="email" name='Email' className="form-control" value={updateFields.Email} onChange={handleChangeFields}/>
                {error.Email && <span className='text-danger'>{error.Email}</span>}
            </div>
            <div className="form-group">
                <label>Phone *</label>
                <input type="tel" name='Mobile' className="form-control" value={state.mobile} disabled/>
            </div>
            {validation && <span className='text-danger'>{validation}</span>}
            <button type="submit" className="gradient_btn">
            SAVE CHANGES
            </button>
        </form>
        <div className="text-center do_later">
            <Link to={shopParam ? shopParam : process.env.REACT_APP_HOME_URL}>I'll add later.</Link>
        </div>
        </div>
    </div>
  )
}

export default PendingProfile