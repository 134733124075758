import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../utils/ApiRequest";


// list all addresses
export const fetchAddressList = createAsyncThunk("address/fetchAddressList", async () => {
    try{
        const response = await instance.get('/account/addresses/list');
        return response.data;
    }catch(error){
        throw error;
    }
  });

// add address
export const AddAddress = createAsyncThunk("address/AddAddress", async (addressData) => {
  try{
      const response = await instance.post('/account/addresses/add', addressData);
      return response.data;
  }catch(error){
      throw error;
  }
});

// update address
export const UpdateAddress = createAsyncThunk("address/UpdateAddress", async (addressId, addressData) => {
  try{
      const response = await instance.put(`/account/addresses/${addressId}/update`, addressData);
      return response.data;
  }catch(error){
      throw error;
  }
});

// delete address
export const DeleteAddress = createAsyncThunk("address/DeleteAddress", async (id) => {
  try{
      const response = await instance.delete(`/account/addresses/${id}/remove`);
      return response.data;
  }catch(error){
      throw error;
  }
});

// get address
export const GetAddress = createAsyncThunk("address/GetAddress", async (addressId) => {
  try{
      const response = await instance.get(`/account/addresses/${addressId}/fetch`);
      return response.data;
  }catch(error){
      throw error;
  }
});

const AddressSlice = createSlice({
    name: 'address',
    initialState:{
        address: [],
        getAddress : {},
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
      .addCase(fetchAddressList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAddressList.fulfilled, (state, action) => {
        state.status = 'Success';
        state.address = action.payload;
      })
      .addCase(fetchAddressList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      .addCase(AddAddress.fulfilled, (state, action) => {
        state.status = 'Success';
        state.error = action.error;
      })
      .addCase(GetAddress.fulfilled, (state, action) => {
        state.status = 'Success';
        state.getAddress = action.payload;
        state.error = null;
      })
      .addCase(UpdateAddress.fulfilled, (state, action) => {
        state.status = 'Success';
        state.error = null;
      })
      .addCase(DeleteAddress.fulfilled, (state, action) => {
        state.status = 'Success';
        state.error = null;
      })
    }
})

export default AddressSlice.reducer;
