import React, { useCallback, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { instance } from '../../../utils/ApiRequest';
import { useEffect } from 'react';
import OrderDetail from '../../Checkout/Orders/OrderDetail';
import PrintOrderDetail from '../../Checkout/Orders/PrintOrderDetail';

function ViewOrder() {

    const [OrderData, setOrderDetail] = useState(null);
    const [PrintOrderData, setPrintOrderData] = useState(null);
    const {id} = useParams();

    const location = useLocation();

    const FetchOrderDetails = useCallback(async() => {
        await instance.get(`/account/orders/shop/fetch/${id}`)
        .then(res => {
            setOrderDetail(res.data.Data);
        })
        .catch(err => {
            
        })
    },[id])

    const FetchPrintOrderDetails = useCallback(async() => {
        await instance.get(`/account/orders/print/fetch/${id}`)
        .then(res => {
            setPrintOrderData(res.data.Data);
        })
        .catch(err => {
            
        })
    },[id])

    useEffect(() => {
        if(location.pathname.includes("/orders/shop")){
            FetchOrderDetails();
        }else{
            FetchPrintOrderDetails();
        }
    }, [location, FetchOrderDetails, FetchPrintOrderDetails])
  return (
    <div className='view_order'>
        {
            (OrderData || PrintOrderData) ? 
                PrintOrderData ? 
                <PrintOrderDetail OrderData = {PrintOrderData}/>
                :
                <OrderDetail OrderData={OrderData} />
            : <div></div>
        }
    </div>
  )
}

export default ViewOrder