import React from 'react';


function Footer() {
    return (
    <div>
        <iframe src={process.env.REACT_APP_IFRAME_URL} className='iframe-styles' title='Footer' style={{border: "none", width: "100%", overflow:"hidden !important"}} scrolling="no"></iframe>
    </div>
    );
}

export default React.memo( Footer );