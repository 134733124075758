import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Shop/shop.css";
import cart from '../Shop/cart.png'
import { instance } from "../../../utils/ApiRequest";
import PrintShipping from "../Shipping/PrintShipping";
import PrintBilling from "../Billing/PrintBilling";
import PrintSummary from "../Summary/PrintSummary";
import PrintPayment from "../Payment/PrintPayment";

function Print({ setOrderDetail }) {
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutDetails, setCheckoutDetails] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const Shipping = location.pathname === "/checkout/print/shipping";
  const Billing = location.pathname === "/checkout/print/billing";
  const Payment = location.pathname === "/checkout/print/payment";
  const Summary = location.pathname === "/checkout/print/summary";

  const FetchCheckout = async () => {
    setIsLoading(true);
    await instance
      .get("/account/checkout/print/fetch")
      .then((res) => {
        setCheckoutDetails(res.data.Data);
      })
      .catch((err) => {
      });
    setIsLoading(false);
  };

  useEffect(() => {
    FetchCheckout();
  }, [navigate]);

  return (
    <div className="shop_checkout">
      { 
      !isLoading ?
      checkoutDetails ? (
        <>
          <div className="step-checkout">
            <ul className="step-checkout_list" id="checkoutList">
              <li
                role="button"
                className={`step-checkout_item ${Shipping ? "active" : ""}`}
                id="stepCheckoutItem1"
              >
                <span>Shipping address</span>
              </li>
              <li
                role="button"
                className={`step-checkout_item ${Billing ? "active" : ""}`}
                id="stepCheckoutItem2"
              >
                <span>Billing address</span>
              </li>
              <li
                role="button"
                className={`step-checkout_item ${Payment ? "active" : ""}`}
                id="stepCheckoutItem3"
              >
                <span>Payment</span>
              </li>
              <li
                role="button"
                className={`step-checkout_item ${Summary ? "active" : ""}`}
                id="stepCheckoutItem4"
              >
                <span>Summary</span>
              </li>
            </ul>
          </div>
          {Shipping && <PrintShipping checkoutDetails={checkoutDetails} />}
          {Billing && <PrintBilling checkoutDetails={checkoutDetails} />}
          {Payment && <PrintPayment checkoutDetails={checkoutDetails} />}
          {Summary && <PrintSummary setOrderDetail={setOrderDetail} />}
        </>
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3><strong>Checkout</strong></h3>
                </div>
                <div className="card-body cart">
                  <div className="col-sm-12 empty-cart-cls text-center">
                    <img
                      src={cart}
                      width="130"
                      height="130"
                      alt="cart"
                      className="img-fluid mb-4 mr-3"
                    />
                    <h3>
                      <strong>Your Cart is Empty</strong>
                    </h3>
                    <h4>Add something to make me happy :)</h4>
                    <a
                      href={process.env.REACT_APP_PRINT_URL}
                      className="btn btn-outline-primary m-3"
                      data-abc="true"
                    >
                      back to shopping
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      ):
      <div></div>
      }
    </div>
  );
}

export default Print;
