import React from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

function MobileMenu() {
    function hideMobileMenu() {
        document.querySelector( 'body' ).classList.remove( 'mmenu-active' );
    }

    return (
        <div className="mobile-menu-container">
            <div className="mobile-menu-wrapper">
                <span className="mobile-menu-close" onClick={ hideMobileMenu }><i className="icon-close"></i></span>
                <Tabs defaultIndex={ 0 } selectedTabClassName="show">
                    <TabList className="nav nav-pills-mobile" role="tablist">
                        <Tab className="nav-item text-center">
                            <span className="nav-link">Menu</span>
                        </Tab>
                    </TabList>
                    <div className="tab-content">
                        <TabPanel>
                            <nav className="mobile-nav">
                                <ul className="mobile-menu">
                                    <li>
                                        <a href={process.env.REACT_APP_HOME_URL} className="sf-with-ul">Home</a>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_SHOP_URL}`} className="sf-with-ul" scroll={ false }>Shop</a>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_PRINT_URL}`} className="sf-with-ul">Print</a>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_SUPPORT_URL}`} className="sf-with-ul">Support</a>
                                    </li>
                                </ul>
                            </nav>
                        </TabPanel>
                    </div>
                </Tabs>

                <div className="social-icons">
                    <a href="https://www.facebook.com/ocean3d.in" target='_blank' rel="noreferrer" className="social-icon" title="Facebook"><i className="icon-facebook-f"></i></a>
                    <a href="https://www.instagram.com/ocean3d.in/" target='_blank' rel="noreferrer" className="social-icon" title="Instagram"><i className="icon-instagram"></i></a>
                    <a href="https://www.youtube.com/@ocean3dindia" target='_blank' rel="noreferrer" className="social-icon" title="Youtube"><i className="icon-youtube"></i></a>
                </div>
            </div>
        </div>
    )
}

export default MobileMenu;