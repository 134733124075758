import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useSize from '../../Size/useSize';
import NoResult from '../../Features/NoResult';

function OrderPrint({printOrders}) {
    const width = useSize();
    const navigate = useNavigate();

    function OnView(OrderID){
        navigate(`/orders/print/view-order/${OrderID}`)
    }
  return (
    <div className='orders_list_print orders_list'>
        {printOrders.length > 0 ? 
        width < 980 ?
            printOrders.map((item, index) => (
                <div className='mob-order' key={index} onClick={() => OnView(item.OrderID)}>
                    <div className='order-data'>
                        <h4>{item.OrderID}</h4>
                        <span>Your Order is {item.OrderStatus}</span>
                    </div>
                    <div className='date-icon'>
                        <span>Placed on {item.OrderDate.split('T')[0]}</span>
                        <span className='gg-chevron-right'>
                        </span>
                    </div>
                </div>
            )) :
        <table className="rwd-table">
            <tbody>
            <tr>
                <th>Order</th>
                <th>Date</th>
                <th>Status</th>
                <th>Payment Method</th>
                <th>Total</th>
                <th>Action</th>
            </tr>
            {printOrders.map((item, index) => (
                <tr key={index}>
                    <td data-th="Order">
                    {item.OrderID}
                    </td>
                    <td data-th="Date">
                    {item.OrderDate.split('T')[0]}
                    </td>
                    <td data-th="Status">
                    {item.OrderStatus}
                    </td>
                    <td data-th="Payment Method">
                    {item.PaymentDetail.PaymentMethod}
                    </td>
                    <td data-th="Total">
                    ₹{item.PricingDetail.TotalPrice}
                    </td>
                    <td data-th="Action">
                        <Link to={`/orders/print/${item.OrderID}`} className=''>View</Link>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
        : 
        <NoResult 
            title="No orders placed yet" 
            description="Orders placed in Print platform will appear here!"
            btnName = "Start Printing"
            btnUrl = {process.env.REACT_APP_PRINT_URL}
        />
        }
    </div>
  )
}

export default OrderPrint